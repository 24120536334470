import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import "../css/landingPage.css";
import cst from "../images/partnerLogos/cst.png";
import kenli from "../images/partnerLogos/kenli.png";
import kmc from "../images/partnerLogos/kmc.png";
import maxxis from "../images/partnerLogos/maxxis.png";
import wtb from "../images/partnerLogos/wtb.png";
import schwalbe from "../images/partnerLogos/schwalbe.png";
import neco from "../images/partnerLogos/neco.png";
import ralson from "../images/partnerLogos/ralson.png";
import prowheel from "../images/partnerLogos/prowheel.png";
import sunrace from "../images/partnerLogos/sunrace.png";
import shimano from "../images/partnerLogos/shimano.png";
import sram from "../images/partnerLogos/sram.png";
import rockshox from "../images/partnerLogos/rockshox.png";
import microshift from "../images/partnerLogos/microshift.png";
import axios from "axios";
import PhoneInput from "react-phone-number-input";
import Meta from "../components/Meta";
import Modal from "../components/Modal";
import ModalMobile from "../components/ModalMobile";
import ReactPlayer from "react-player";
import CustomBike from "../components/CustomBike";
import "../css/navbar.css";
import logo from "../images/brandLogos/Dynem red white.png";
import { Icon } from "@iconify/react";

const GetQuoteLanding = () => {
  const featuresData = [
    {
      key: "precisionControllers",
      heading: "Precision Controllers",
      description:
        "Controllers in an e-bike employs sensors and algorithms to adjust power delivery seamlessly and its intelligent system enhances performance and makes the e-bike more intuitive and user-friendly.",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/Controlerrrr.png",
    },
    {
      key: "highPerformanceChargers",
      heading: "High Performance Chargers",
      description:
        "The chargers come in a range of customisable options that cater to preferences, based on global compatibility, portability or extended power flexibility.",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/Chargerrrr.png",
    },
    {
      key: "multifunctionalDisplays",
      heading: "Multifunctional Displays",
      description:
        "These LCD displays offer real-time data like speed and battery which empowers riders to monitor and adjust their riding modes.",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/Displayyyyy.png",
    },
    {
      key: "longLastingBatteries",
      heading: "Long Lasting Batteries",
      description:
        "This electric system stores energy and powers the electric motor. This technology has been designed to ensure consistent performance, longer battery life, and optimal power delivery, enhancing the overall riding experience.",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/Baterryyyyy.png",
    },
    {
      key: "efficientMotors",
      heading: "Efficient Motors",
      description:
        "Our versatile and innovative motors have been designed to offer reliable and accurate performance, ensuring optimal utility in various applications.",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/motorrrr.png",
    },
  ];
  const [activeFeature1, setActiveFeature1] = useState("precisionControllers");
  const handleFeature1Click = (feature) => {
    setActiveFeature1(feature.key);
  };
  const getActiveFeature1 = () => {
    return featuresData.find((feature) => feature.key === activeFeature1);
  };
  const activeFeatureObj1 = getActiveFeature1();
  const [open, openNav] = useState(false);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  }, []);

  const closeNavforDeadLink = () => {
    openNav(false);
  };

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [country, setCountry] = useState("");
  const [enquiry, setEnquiry] = useState("");

  const componentData = [
    {
      key: "Frame",
    },
    {
      key: "Motor",
    },
    {
      key: "Derailleur",
    },
    {
      key: "Battery",
    },
    {
      key: "Display",
    },
  ];
  const [activeComponent, setActiveComponent] = useState("Frame");
  const handleComponentClick = (feature) => {
    setActiveComponent(feature.key);
  };

  const navigate = useNavigate();

  const submit = async (e) => {
    e.preventDefault();
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const reqBody = {
      name,
      email,
      phoneNumber,
      companyName,
      country,
      enquiry,
    };
    const { data } = await axios.post(
      "/api/form/request-a-quote",
      reqBody,
      config
    );
    if (data) {
      navigate("/details-submitted");
    }
  };
  return (
    <>
      {/* landing page main banner */}
      <Meta
        title={"Get a Quote - Get Wholesale E-bike Purchase Quote from Dynem"}
        description={
          "Get a wholesale e-bike purchase quote from Dynem, a leading e-bike manufacturer and supplier. Request your personalized quote today for bulk orders."
        }
        cannonicalTag={"https://www.dynem.in/get-quote"}
      />
      {/* desktop navbar */}
      <div
        className="position-sticky w-100 navbar"
        style={{ zIndex: "99", top: "0%" }}
      >
        <div className="row container mx-auto px-3 px-lg-0">
          <div className="col-12 col-md-4 d-flex justify-content-start p-0">
            <Link to={"/"}>
              <img src={logo} alt="dynem-logo" className="logo"></img>
            </Link>
          </div>
          <div className="col-5 d-flex nav-items p-0"></div>
          <div className="col-3 p-0 d-none d-lg-block d-md-block">
            <div className="d-flex justify-content-end align-items-center">
              <Modal className="primary-button fw-bold px-4 py-2 d-lg-block d-none" />
            </div>
          </div>
        </div>
      </div>
      <div className="get-quote-landing">
        {/* hero-banner */}
        <div className="hero-banner position-relative">
          <picture className="main-banner-img">
            <source
              media="(max-width:650px)"
              srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/banners/heroBanner_mobile.jpg"
            />
            <source
              media="(min-width:651px)"
              srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/banners/heroBanner.jpg"
            />
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/banners/heroBanner.jpg"
              alt="hero-banner"
              className="d-block w-100"
            />
          </picture>
          <div
            className="d-none d-lg-block position-absolute"
            style={{ top: "10%", left: "7%" }}
          >
            <h1
              className="poppins-bold text-light"
              style={{ fontSize: "3.5em" }}
            >
              <span style={{ color: "#ff0000" }}>POWER UP</span> YOUR <br></br>
              EBIKE PRODUCTION
            </h1>
            <p className="text-light w-50 tablet-display-off">
              Elevate your E-Bike production with Dynem s precision engineering
              and expert craftsmanship, delivering superior results that set
              your brand apart.
            </p>
          </div>
          <div
            className="d-none position-absolute tablet-display-on"
            style={{ top: "10%", left: "7%" }}
          >
            <h1
              className="poppins-bold text-light"
              style={{ fontSize: "2.5em" }}
            >
              <span style={{ color: "#ff0000" }}>POWER UP</span> YOUR <br></br>
              EBIKE PRODUCTION
            </h1>
            <p className="text-light w-50 tablet-display-off">
              Elevate your E-Bike production with Dynem s precision engineering
              and expert craftsmanship, delivering superior results that set
              your brand apart.
            </p>
          </div>
          <div
            className="d-lg-none position-absolute tablet-display-off"
            style={{ top: "0%" }}
          >
            <div className="d-flex justify-content-center w-100 p-4">
              <h1
                className="poppins-bold text-light"
                style={{ fontSize: "2.5em" }}
              >
                <span style={{ color: "#ff0000" }}>POWER UP</span> YOUR{" "}
                <br></br>
                EBIKE PRODUCTION
              </h1>
            </div>
          </div>
          <div
            className="d-lg-block d-none position-absolute"
            style={{
              bottom: "10%",
              left: "7%",
              backgroundColor: "rgba(151, 151, 151, 0.56)",
            }}
          >
            <div
              className="d-flex p-3 px-5 text-center poppins-medium"
              style={{
                gap: "2em",
              }}
            >
              <h5 className="text-light">
                5,00,000+<br></br>E-Bike Capacity
              </h5>
              <h5 className="text-light">
                4,00,000+<br></br>Component Capacity
              </h5>
              <h5 className="text-light">
                In House<br></br>Drive Train
              </h5>
              <Modal className="primary-button fw-bold px-4 py-2 d-lg-block d-none" />
            </div>
          </div>
          <div
            className="d-none tablet-display-on position-absolute"
            style={{
              bottom: "10%",
              left: "7%",
              backgroundColor: "rgba(151, 151, 151, 0.56)",
            }}
          >
            <div
              className="d-flex p-3 text-center poppins-medium"
              style={{
                gap: "1.5em",
              }}
            >
              <p className="text-light mb-0">
                5,00,000+<br></br>E-Bike Capacity
              </p>
              <p className="text-light mb-0">
                4,00,000+<br></br>Component Capacity
              </p>
              <p className="text-light mb-0">
                In House<br></br>Drive Train
              </p>
              <Modal className="primary-button fw-bold px-3 py-1" />
            </div>
          </div>
          <div
            className="d-lg-none position-absolute w-100 tablet-display-off"
            style={{
              bottom: "10%",
              backgroundColor: "rgba(151, 151, 151, 0.56)",
            }}
          >
            <div
              className="d-flex p-3 text-center poppins-medium"
              style={{
                gap: "1.7em",
                fontSize: "0.8em",
              }}
            >
              <h5 className="text-light">
                5,00,000+<br></br>E-Bike Capacity
              </h5>
              <h5 className="text-light">
                4,00,000+<br></br>Component Capacity
              </h5>
              <h5 className="text-light">
                In House<br></br>Drive Train
              </h5>
            </div>
          </div>
          <div className="d-lg-none get-quote-banner-button-mb position-absolute tablet-display-off">
            <div className="d-flex" style={{ gap: "1.5em" }}>
              <ModalMobile className="primary-button fw-bold px-4 py-2" />
            </div>
          </div>
        </div>
        {/* world class products */}
        <div className="">
          <div className=" mt-5 py-4 py-lg-5">
            <h2 className="poppins-medium mb-3 mb-lg-5 text-center container">
              Class Manufacturing, from India, for the World!
            </h2>
            <div className="news py-5">
              <Splide
                className="mb-2 mb-lg-0 p-0 align-items-center container"
                options={{
                  arrows: false,
                  drag: false,
                  type: "slide",
                  pagination: false,
                  autoplay: false,
                  perMove: 1,
                  gap: "2em",
                  padding: "0em",
                  pauseOnHover: true,
                  resetProgress: false,
                  perPage: 4,
                  speed: 1000,
                  interval: 3000,
                  breakpoints: {
                    700: {
                      drag: true,
                      perPage: 1.3,
                      arrows: false,
                      perMove: 1,
                      gap: "1em",
                      padding: "0em",
                      pauseOnHover: false,
                      resetProgress: false,
                      focus: 0,
                      start: 0,
                      speed: 1000,
                      interval: 3000,
                    },
                  },
                }}
                muted={true}
              >
                <SplideSlide className="h-100">
                  <div className="p-3" style={{ backgroundColor: "white" }}>
                    <div>
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/products/ecycle.jpg"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <h5 className="poppins-medium mt-3 text-center">E-bikes</h5>
                  </div>
                </SplideSlide>
                <SplideSlide className="h-100">
                  <div className="p-3" style={{ backgroundColor: "white" }}>
                    <div>
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/products/motor.jpg"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <h5 className="poppins-medium mt-3 text-center">Motor</h5>
                  </div>
                </SplideSlide>
                <SplideSlide className="h-100">
                  <div className="p-3" style={{ backgroundColor: "white" }}>
                    <div>
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/products/battery.jpg"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <h5 className="poppins-medium mt-3 text-center">Battery</h5>
                  </div>
                </SplideSlide>
                <SplideSlide className="h-100">
                  <div className="p-3" style={{ backgroundColor: "white" }}>
                    <div>
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/products/dispaly.jpg"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <h5 className="poppins-medium mt-3 text-center">Display</h5>
                  </div>
                </SplideSlide>
              </Splide>
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center py-4">
            <Modal className="primary-button fw-bold px-4 py-2 d-lg-block d-none" />
            <ModalMobile className="primary-button fw-bold px-4 py-2 d-lg-none" />
          </div>
        </div>
        {/* company logos */}
        <div className="container my-5 py-lg-5 d-none d-lg-block">
          <Splide
            className="flex align-items-center"
            extensions={{ AutoScroll }}
            options={{
              type: "loop",
              autoScroll: {
                speed: 1,
                pauseOnHover: false,
              },
              perPage: 6,
              drag: "free",
              arrows: false,
              pagination: false,
              pauseOnHover: false,
              gap: "3em",
              padding: "1em",
              breakpoints: {
                640: {
                  perPage: 3,
                  gap: "1em",
                },
              },
            }}
          >
            <SplideSlide className="h-100">
              <div>
                <img src={shimano} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={kmc} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={maxxis} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={wtb} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={rockshox} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={neco} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={ralson} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={sunrace} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={microshift} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={sram} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={schwalbe} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
          </Splide>
        </div>
        <div className="my-5 py-lg-5 d-lg-none">
          <Splide
            className="flex align-items-center"
            extensions={{ AutoScroll }}
            options={{
              type: "loop",
              autoScroll: {
                speed: 1,
                pauseOnHover: false,
              },
              perPage: 6,
              drag: "free",
              arrows: false,
              pagination: false,
              pauseOnHover: false,
              gap: "3em",
              padding: "1em",
              breakpoints: {
                640: {
                  perPage: 3,
                  gap: "1em",
                },
              },
            }}
          >
            <SplideSlide className="h-100">
              <div>
                <img src={shimano} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={kmc} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={maxxis} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={wtb} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={rockshox} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={neco} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={ralson} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={sunrace} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={microshift} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={sram} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={schwalbe} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
          </Splide>
        </div>
        {/* why choose us */}
        <div className="news">
          <div className="container mt-5 pt-lg-5 pt-5">
            <h2 className="poppins-medium mb-2">Your Partner in Success</h2>
            <h4 className="">Here’s why we stand out</h4>
            <div className="py-5">
              <div className="d-flex flex-wrap justify-content-between text-center w-100 mb-3 mb-lg-5 why-choose-us">
                <div className="flex-item">
                  <div className="d-flex justify-content-center mb-3">
                    <img
                      className="img-fluid"
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/production.png"
                    ></img>
                  </div>

                  <p className="m-0 poppins-medium">
                    500,000<br></br>Production Capacity
                  </p>
                </div>
                <div className="flex-item">
                  <div className="d-flex justify-content-center mb-3">
                    <img
                      className="img-fluid"
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/component.png"
                    ></img>
                  </div>
                  <p className="m-0 poppins-medium">
                    400,000&nbsp;Component Capacity
                  </p>
                </div>
                <div className="flex-item">
                  <div className="d-flex justify-content-center mb-3">
                    <img
                      className="img-fluid"
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/cost.png"
                    ></img>
                  </div>
                  <p className="m-0 poppins-medium">
                    87%<br></br>Cost Benefits
                  </p>
                </div>
                <div className="flex-item">
                  <div className="d-flex justify-content-center mb-3">
                    <img
                      className="img-fluid"
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/iot.png"
                    ></img>
                  </div>
                  <p className="m-0 poppins-medium">
                    IOT enabled<br></br>solutions
                  </p>
                </div>
              </div>
              <div className="d-flex flex-wrap flex-wrap-2 justify-content-between text-center w-100 why-choose-us">
                <div className="flex-item">
                  <div className="d-flex justify-content-center mb-3">
                    <img
                      className="img-fluid"
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/certificate.png"
                    ></img>
                  </div>

                  <p className="mt-2 poppins-medium">
                    Europe<br></br>Certifications
                  </p>
                </div>
                <div className="flex-item">
                  <div className="d-flex justify-content-center mb-3">
                    <img
                      className="img-fluid"
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/in-stock.png"
                    ></img>
                  </div>
                  <p className="mt-2 poppins-medium">
                    Ready stock<br></br>available
                  </p>
                </div>
                <div className="flex-item">
                  <div className="d-flex justify-content-center mb-3">
                    <img
                      className="img-fluid"
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/automation.png"
                    ></img>
                  </div>
                  <p className="mt-2 poppins-medium">
                    All components<br></br>inhouse production
                  </p>
                </div>
                <div className="flex-item">
                  <div className="d-flex justify-content-center mb-3">
                    <img
                      className="img-fluid"
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/container.png"
                    ></img>
                  </div>
                  <p className="mt-2 poppins-medium">
                    Legal Anti-dumping<br></br>solution
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* range of dts */}
        <div className="container mt-5 pt-4 pt-lg-5">
          <h2 className="poppins-medium mb-4 mb-lg-5">
            Components that Power Every Mile
          </h2>
          <div className="d-lg-none pt-4">
            <Splide
              className="mb-5 p-0 align-items-center"
              options={{
                drag: true,
                perPage: 1.2,
                arrows: false,
                pagination: false,
                autoplay: false,
                perMove: 1,
                interval: 4000,
                gap: "1em",
                padding: "0em",
                pauseOnHover: false,
                resetProgress: false,
                focus: 0,
                start: 0,
                speed: 500,
              }}
            >
              {featuresData.slice(0, 4).map((feature) => (
                <SplideSlide className="feature-btn-width">
                  <button
                    key={feature}
                    className={
                      feature.key === activeFeature1
                        ? "em-btn-1 rounded-0"
                        : "em-btn-2 rounded-0"
                    }
                    onClick={() => handleFeature1Click(feature)}
                  >
                    {feature.heading}
                  </button>
                </SplideSlide>
              ))}
            </Splide>
          </div>
          <div className="mb-5 d-none d-lg-block">
            <div
              className="d-flex justify-content-center mb-3"
              style={{ gap: "1em" }}
            >
              {featuresData.map((feature) => (
                <button
                  key={feature}
                  className={
                    feature.key === activeFeature1
                      ? "em-btn-1 rounded-0"
                      : "em-btn-2 rounded-0"
                  }
                  onClick={() => handleFeature1Click(feature)}
                >
                  {feature.heading}
                </button>
              ))}
            </div>
          </div>
          <div className="d-none d-lg-block my-5 px-5">
            <div className="d-flex align-items-center justify-content-center">
              <div className="feature-text w-100">
                <h1>{activeFeatureObj1.heading}</h1>{" "}
                <p>{activeFeatureObj1.description}</p>
              </div>
              <div className="w-100">
                <img
                  src={activeFeatureObj1.image}
                  alt="amigo-feature"
                  className="img-fluid"
                ></img>
              </div>
            </div>
          </div>
          <div className="row mt-5 d-lg-none">
            <div className="col-12 col-lg-6 feature-text">
              <h1>{activeFeatureObj1.heading}</h1>{" "}
              <p>{activeFeatureObj1.description}</p>
            </div>
            <div className="col-12 col-lg-6">
              <div className="d-flex d-lg-block justify-content-center">
                <img
                  src={activeFeatureObj1.image}
                  alt="amigo-feature"
                  className="img-fluid mx-auto"
                ></img>
              </div>
            </div>
          </div>
        </div>
        {/* range of e-bikes */}
        <div className="mt-5 pt-lg-5 ">
          <div className="position-relative">
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/banners/breaker2.jpg"
              className="img-fluid"
            ></img>
            <div
              className="position-absolute d-lg-block d-none tablet-display-off"
              style={{ top: "25%", left: "58%" }}
            >
              <div>
                <h1
                  className="poppins-medium text-light"
                  style={{ fontSize: "5em" }}
                >
                  Explore Our <br></br>Electric Fleet
                </h1>
              </div>
            </div>
            <div
              className="position-absolute d-none large-tablet-screen-on"
              style={{ top: "25%", left: "58%" }}
            >
              <div>
                <h1
                  className="poppins-medium text-light"
                  style={{ fontSize: "3.3em" }}
                >
                  Explore Our <br></br>Electric Fleet
                </h1>
              </div>
            </div>
            <div
              className="position-absolute d-none tablet-display-on"
              style={{ top: "25%", left: "58%" }}
            >
              <div>
                <h1
                  className="poppins-medium text-light"
                  style={{ fontSize: "2.5em" }}
                >
                  Explore Our <br></br>Electric Fleet
                </h1>
              </div>
            </div>
          </div>
          <h2 className="poppins-medium d-lg-none my-4 text-center tablet-display-off">
            Explore Our Electric Fleet
          </h2>
          <div className="container py-3 d-lg-none tablet-display-off">
            <Splide
              className="mb-2 mb-lg-0 p-0 align-items-center container"
              options={{
                arrows: false,
                drag: false,
                type: "slide",
                pagination: false,
                autoplay: false,
                perMove: 1,
                gap: "2em",
                padding: "0em",
                pauseOnHover: true,
                resetProgress: false,
                perPage: 4,
                speed: 1000,
                interval: 3000,
                breakpoints: {
                  700: {
                    drag: true,
                    perPage: 1.3,
                    arrows: false,
                    perMove: 1,
                    gap: "1em",
                    padding: "0em",
                    pauseOnHover: false,
                    resetProgress: false,
                    focus: 0,
                    start: 0,
                    speed: 1000,
                    interval: 3000,
                  },
                },
              }}
              muted={true}
            >
              <SplideSlide className="h-100">
                <div className="p-3 news">
                  <div>
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/ebikes/mountain.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="mt-3 text-center">Mountain E-bikes</h4>
                  <div style={{ height: "125px" }}>
                    <p className="text-center m-0">
                      We're not just a mountain E-bike manufacturer; we're
                      trailblazers in off-road adventure. Explore a range of
                      e-bikes meticulously crafted for rugged terrains and
                      thrilling rides.
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-3 news">
                  <div>
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/ebikes/fat-tyre.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="mt-3 text-center">
                    Folding & Fat Tyre E-Bikes
                  </h4>
                  <div style={{ height: "125px" }}>
                    <p className="text-center m-0">
                      As a top-tier folding eBike manufacturer, DYNEM brings you
                      the ultimate blend of convenience and style. Our folding
                      e-bikes are perfect for city explorers and jet-setters.
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-3 news">
                  <div>
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/ebikes/city.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="mt-3 text-center">City E-Bikes</h4>
                  <div style={{ height: "125px" }}>
                    <p className="text-center m-0">
                      As a premier city bike manufacturer, DYNEM redefines urban
                      commuting with sleek, efficient, and stylish rides. Our
                      city bikes blend cutting-edge technology with modern
                      design, ensuring you glide through traffic with ease.
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-3 news">
                  <div>
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/ebikes/cargo.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="mt-3 text-center">Delivery E-Bikes</h4>
                  <div style={{ height: "125px" }}>
                    <p className="text-center m-0">
                      As a leading delivery bike manufacturer, DYNEM delivers
                      top-tier performance and reliability for every delivery.
                      Our e-bikes are designed for the demands of urban
                      logistics, powerful motors, and ample cargo space.
                    </p>
                  </div>
                </div>
              </SplideSlide>
            </Splide>
          </div>
          <div className="range-ebikes container d-lg-block d-none py-5 my-5 px-5 tablet-display-on">
            <Splide
              className="mb-2 mb-lg-0 p-0 align-items-center text-center"
              options={{
                arrows: true,
                start: 0,
                drag: false,
                type: "loop",
                pagination: true,
                autoplay: true,
                gap: "3em",
                padding: "1em",
                pauseOnHover: true,
                resetProgress: false,
                perPage: 1,
                speed: 1000,
                interval: 4000,
                breakpoints: {
                  700: {
                    drag: true,
                    autoplay: false,
                    pagination: "false",
                    perPage: 1,
                    perMove: 1,
                    gap: "2em",
                    padding: "0em",
                    arrows: false,
                    pauseOnHover: false,
                    resetProgress: false,
                  },
                },
              }}
              muted={true}
            >
              <SplideSlide className="h-100">
                <div className="p-4 pt-0 p-lg-0 d-flex align-items-center ecycle-range-card">
                  <div className="">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/ebikes/mountain.png"
                      alt=""
                      className="w-100"
                    />
                  </div>
                  <div className="text-start p-3">
                    <h4 className="poppins-medium">Mountain E-Bikes</h4>
                    <p className="">
                      We're not just a mountain E-bike manufacturer; we're
                      trailblazers in off-road adventure. Explore a range of
                      e-bikes meticulously crafted for rugged terrains and
                      thrilling rides.
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-4 pt-0 p-lg-0 d-flex align-items-center ecycle-range-card">
                  <div className="">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/ebikes/fat-tyre.png"
                      alt=""
                      className="w-100"
                    />
                  </div>
                  <div className="text-start p-3">
                    <h4 className="poppins-medium">
                      Folding & Fat Tyre E-Bikes
                    </h4>
                    <p className="">
                      As a top-tier folding eBike manufacturer, DYNEM brings you
                      the ultimate blend of convenience and style. Our folding
                      e-bikes are perfect for city explorers and jet-setters.
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-4 pt-0 p-lg-0 d-flex align-items-center ecycle-range-card">
                  <div className="">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/ebikes/city.png"
                      alt=""
                      className="w-100"
                    />
                  </div>
                  <div className="text-start p-3">
                    <h4 className="poppins-medium">City E-Bikes</h4>
                    <p className="">
                      As a premier city bike manufacturer, DYNEM redefines urban
                      commuting with sleek, efficient, and stylish rides. Our
                      city bikes blend cutting-edge technology with modern
                      design.
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-4 pt-0 p-lg-0 d-flex align-items-center ecycle-range-card">
                  <div className="">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/ebikes/cargo.png"
                      alt=""
                      className="w-100"
                    />
                  </div>
                  <div className="text-start p-3">
                    <h4 className="poppins-medium">Delivery E-Bikes</h4>
                    <p className="">
                      As a leading delivery bike manufacturer, DYNEM delivers
                      top-tier performance and reliability for every delivery.
                      Our e-bikes are designed for the demands of urban
                      logistics and ample cargo space.
                    </p>
                  </div>
                </div>
              </SplideSlide>
            </Splide>
          </div>
        </div>
        {/* certifications */}
        <div className="news mt-5 pt-lg-5 pt-4 mb-5">
          <h2 className="poppins-medium mb-3 mb-lg-4 container">
            Certifications
          </h2>
          <div className="container pb-4">
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/certifications__png.png"
              alt="certifications"
              className="w-100"
            ></img>
          </div>
        </div>
        <div className="container mt-5 pt-lg-5">
          <h2 className="poppins-medium mb-2">Make the Shift</h2>
          <h4 className="mb-3 mb-lg-5">Simplify Your Production With Us</h4>
          <div className="container switching">
            <Splide
              className="mb-lg-0 p-0 align-items-center large-tablet-screen-on"
              options={{
                arrows: true,
                start: 0,
                drag: false,
                type: "loop",
                pagination: true,
                autoplay: true,
                gap: "3em",
                padding: "0em",
                pauseOnHover: true,
                resetProgress: false,
                perPage: 4,
                perMove: 1,
                speed: 1000,
                interval: 4000,
                breakpoints: {
                  700: {
                    drag: true,
                    arrows: false,
                    autoplay: false,
                    perPage: 1,
                    perMove: 1,
                    gap: "0em",
                    padding: "0em",
                    pauseOnHover: false,
                    resetProgress: false,
                  },
                },
              }}
              muted={true}
            >
              <SplideSlide className="h-100 ">
                <div className="p-4 pb-0 pt-0 p-lg-0 mb-4">
                  <div className="d-flex justify-content-center">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/1.png"
                      alt=""
                      className="img-fluid col-6 col-md-10"
                    />
                  </div>
                  <div className="text-center text-lg-start">
                    <h5 className="poppins-medium">Mass Production</h5>
                    <p className="m-0">
                      Alignment on production post order finalization
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-4 pb-0 pt-0 p-lg-0 mb-4">
                  <div className="d-flex justify-content-center">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/2.png"
                      alt=""
                      className="img-fluid col-6 col-md-10"
                    />
                  </div>
                  <div className="text-center text-lg-start">
                    <h5 className="poppins-medium">Sampling process</h5>
                    <p className="m-0">
                      Also enabling check on anti dumping tariff validation
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-4 pb-0 pt-0 p-lg-0 mb-4">
                  <div className="d-flex justify-content-center">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/3.png"
                      alt=""
                      className="img-fluid col-6 col-md-10"
                    />
                  </div>
                  <div className="text-center text-lg-start">
                    <h5 className="poppins-medium">
                      Explore our product offering
                    </h5>
                    <p className="m-0">
                      We will match your specs along with offering a complete
                      Localized offering (country of origin)
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-4 pb-0 pt-0 p-lg-0 mb-4">
                  <div className="d-flex justify-content-center">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/4.png"
                      alt=""
                      className="img-fluid col-6 col-md-10"
                    />
                  </div>
                  <div className="text-center text-lg-start">
                    <h5 className="poppins-medium">Arranging factory visit</h5>
                    <p className="m-0">
                      to provide you deep insights on the state of the art
                      machinery and manufacturing process
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-4 pb-0 pt-0 p-lg-0 mb-4">
                  <div className="d-flex justify-content-center">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/5.png"
                      alt=""
                      className="img-fluid col-6 col-md-10"
                    />
                  </div>
                  <div className="text-center text-lg-start">
                    <h5 className="poppins-medium">Product Finalization</h5>
                    <p className="m-0">Post sampling and batch testing</p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-4 pb-0 pt-0 p-lg-0 mb-4">
                  <div className="d-flex justify-content-center">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/6.png"
                      alt=""
                      className="img-fluid col-6 col-md-10"
                    />
                  </div>
                  <div className="text-center text-lg-start">
                    <h5 className="poppins-medium">Support in After-Sales</h5>
                    <p className="m-0">
                      Along with and spares planning - Europe and USA
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-4 pb-0 pt-0 p-lg-0 mb-4">
                  <div className="d-flex justify-content-center">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/7.png"
                      alt=""
                      className="img-fluid col-6 col-md-10"
                    />
                  </div>
                  <div className="text-center text-lg-start">
                    <h5 className="poppins-medium">Extend Logistics Support</h5>
                    <p className="m-0">
                      To enable the best shipping solution with our extended
                      partner network
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-4 pb-0 pt-0 p-lg-0 mb-4">
                  <div className="d-flex justify-content-center">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/8.png"
                      alt=""
                      className="img-fluid col-6 col-md-10"
                    />
                  </div>
                  <div className="text-center text-lg-start">
                    <h5 className="poppins-medium">Send your requirements</h5>
                    <p className="m-0">(bike images and specifications)</p>
                  </div>
                </div>
              </SplideSlide>
            </Splide>
            <div className="container d-none tablet-display-on">
              <Splide
                className="mb-lg-0 p-0 align-items-center"
                options={{
                  arrows: true,
                  start: 0,
                  drag: false,
                  type: "loop",
                  pagination: true,
                  autoplay: true,
                  gap: "0em",
                  padding: "0em",
                  pauseOnHover: true,
                  resetProgress: false,
                  perPage: 4,
                  perMove: 1,
                  speed: 1000,
                  interval: 4000,
                  breakpoints: {
                    700: {
                      drag: true,
                      arrows: false,
                      autoplay: false,
                      perPage: 1,
                      perMove: 1,
                      gap: "0em",
                      padding: "0em",
                      pauseOnHover: false,
                      resetProgress: false,
                    },
                  },
                }}
                muted={true}
              >
                <SplideSlide className="h-100 ">
                  <div className="p-3 pb-0 pt-0 p-lg-0 mb-4">
                    <div className="d-flex justify-content-center">
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/1.png"
                        alt=""
                        className="img-fluid col-6 col-md-10"
                      />
                    </div>
                    <div className="text-center text-lg-start">
                      <h5 className="poppins-medium">Mass Production</h5>
                      <p className="m-0">
                        Alignment on production post order finalization
                      </p>
                    </div>
                  </div>
                </SplideSlide>
                <SplideSlide className="h-100">
                  <div className="p-3 pb-0 pt-0 p-lg-0 mb-4">
                    <div className="d-flex justify-content-center">
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/2.png"
                        alt=""
                        className="img-fluid col-6 col-md-10"
                      />
                    </div>
                    <div className="text-center text-lg-start">
                      <h5 className="poppins-medium">Sampling process</h5>
                      <p className="m-0">
                        Also enabling check on anti dumping tariff validation
                      </p>
                    </div>
                  </div>
                </SplideSlide>
                <SplideSlide className="h-100">
                  <div className="p-3 pb-0 pt-0 p-lg-0 mb-4">
                    <div className="d-flex justify-content-center">
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/3.png"
                        alt=""
                        className="img-fluid col-6 col-md-10"
                      />
                    </div>
                    <div className="text-center text-lg-start">
                      <h5 className="poppins-medium">
                        Explore our product offering
                      </h5>
                      <p className="m-0">
                        We will match your specs along with offering a complete
                        Localized offering (country of origin)
                      </p>
                    </div>
                  </div>
                </SplideSlide>
                <SplideSlide className="h-100">
                  <div className="p-3 pb-0 pt-0 p-lg-0 mb-4">
                    <div className="d-flex justify-content-center">
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/4.png"
                        alt=""
                        className="img-fluid col-6 col-md-10"
                      />
                    </div>
                    <div className="text-center text-lg-start">
                      <h5 className="poppins-medium">
                        Arranging factory visit
                      </h5>
                      <p className="m-0">
                        to provide you deep insights on the state of the art
                        machinery and manufacturing process
                      </p>
                    </div>
                  </div>
                </SplideSlide>
                <SplideSlide className="h-100">
                  <div className="p-3 pb-0 pt-0 p-lg-0 mb-4">
                    <div className="d-flex justify-content-center">
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/5.png"
                        alt=""
                        className="img-fluid col-6 col-md-10"
                      />
                    </div>
                    <div className="text-center text-lg-start">
                      <h5 className="poppins-medium">Product Finalization</h5>
                      <p className="m-0">Post sampling and batch testing</p>
                    </div>
                  </div>
                </SplideSlide>
                <SplideSlide className="h-100">
                  <div className="p-3 pb-0 pt-0 p-lg-0 mb-4">
                    <div className="d-flex justify-content-center">
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/6.png"
                        alt=""
                        className="img-fluid col-6 col-md-10"
                      />
                    </div>
                    <div className="text-center text-lg-start">
                      <h5 className="poppins-medium">Support in After-Sales</h5>
                      <p className="m-0">
                        Along with and spares planning - Europe and USA
                      </p>
                    </div>
                  </div>
                </SplideSlide>
                <SplideSlide className="h-100">
                  <div className="p-3 pb-0 pt-0 p-lg-0 mb-4">
                    <div className="d-flex justify-content-center">
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/7.png"
                        alt=""
                        className="img-fluid col-6 col-md-10"
                      />
                    </div>
                    <div className="text-center text-lg-start">
                      <h5 className="poppins-medium">
                        Extend Logistics Support
                      </h5>
                      <p className="m-0">
                        To enable the best shipping solution with our extended
                        partner network
                      </p>
                    </div>
                  </div>
                </SplideSlide>
                <SplideSlide className="h-100">
                  <div className="p-3 pb-0 pt-0 p-lg-0 mb-4">
                    <div className="d-flex justify-content-center">
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/8.png"
                        alt=""
                        className="img-fluid col-6 col-md-10"
                      />
                    </div>
                    <div className="text-center text-lg-start">
                      <h5 className="poppins-medium">Send your requirements</h5>
                      <p className="m-0">(bike images and specifications)</p>
                    </div>
                  </div>
                </SplideSlide>
              </Splide>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center mt-5">
          <Modal className="primary-button fw-bold px-4 py-2 d-lg-block d-none" />
          <ModalMobile className="primary-button fw-bold px-4 py-2 d-lg-none" />
        </div>
        {/* amigo */}
        <div className="news my-5 py-5">
          <h2 className="poppins-medium mb-5 container">
            Amigo - one app | limitless possibilities
          </h2>
          <div
            className="d-flex container pt-3"
            style={{ overflowX: "auto", gap: "2em" }}
          >
            <div className="mb-4 amigo-spec">
              <div className="position-relative">
                <img
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/amigo/fitness.png"
                  className="w-100"
                ></img>
                <div
                  className="position-absolute tablet-display-off"
                  style={{ top: "17%", right: "17%" }}
                >
                  <div className="d-flex align-items-end">
                    <p className="fw-bold">Fitness Mode</p>
                  </div>
                </div>
                <div
                  className="position-absolute d-none tablet-display-on large-tablet-screen-on"
                  style={{ top: "17%", right: "2%" }}
                >
                  <div className="d-flex align-items-end">
                    <p className="fw-bold" style={{ fontSize: "0.9em" }}>
                      Fitness Mode
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-4 amigo-spec">
              <div className="position-relative">
                <img
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/amigo/diagnostic.png"
                  className="w-100"
                ></img>
                <div
                  className="position-absolute tablet-display-off"
                  style={{ top: "13%", right: "14%" }}
                >
                  <div className="d-flex align-items-end">
                    <p className="fw-bold">
                      Complete Bike<br></br>Diagnostics
                    </p>
                  </div>
                </div>
                <div
                  className="position-absolute d-none tablet-display-on large-tablet-screen-on"
                  style={{ top: "3%", right: "2%" }}
                >
                  <div className="d-flex align-items-end">
                    <p className="fw-bold" style={{ fontSize: "0.9em" }}>
                      Complete Bike<br></br>Diagnostics
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-4 amigo-spec">
              <div className="position-relative">
                <img
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/amigo/sos.png"
                  className="w-100"
                ></img>
                <div
                  className="position-absolute tablet-display-off"
                  style={{ top: "13%", right: "21%" }}
                >
                  <div className="d-flex align-items-end">
                    <p className="fw-bold">
                      Emergency<br></br>SOS Alert
                    </p>
                  </div>
                </div>
                <div
                  className="position-absolute d-none tablet-display-on large-tablet-screen-on"
                  style={{ top: "3%", right: "2%" }}
                >
                  <div className="d-flex align-items-end">
                    <p className="fw-bold" style={{ fontSize: "0.9em" }}>
                      Emergency<br></br>SOS Alert
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center mt-4 mt-lg-5">
          <Modal className="primary-button fw-bold px-4 py-2 d-lg-block d-none" />
          <ModalMobile className="primary-button fw-bold px-4 py-2 d-lg-none" />
        </div>
        {/* testimonials */}
        <div className="mt-5 py-lg-5 container testimonials">
          <h2 className="poppins-medium mb-3 mb-lg-5">Testimonials</h2>
          <div className="mt-5 mt-lg-0 container">
            <Splide
              className="mb-lg-0 p-0 align-items-center"
              options={{
                arrows: true,
                start: 0,
                drag: false,
                type: "loop",
                pagination: true,
                autoplay: true,
                gap: "1.5em",
                padding: "0em",
                pauseOnHover: true,
                resetProgress: false,
                perPage: 1,
                perMove: 1,
                speed: 1000,
                interval: 4000,
                breakpoints: {
                  700: {
                    drag: true,
                    arrows: false,
                    autoplay: false,
                    perPage: 1,
                    perMove: 1,
                    gap: "1em",
                    padding: "0em",
                    pauseOnHover: false,
                    resetProgress: false,
                  },
                },
              }}
              muted={true}
            >
              <SplideSlide className="h-100">
                <div className="d-flex p-lg-4" style={{ gap: "2em" }}>
                  <div className="w-25 d-none d-lg-block tablet-display-on">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/testimonial1.png"
                      className="rounded-0"
                    ></img>
                  </div>
                  <div className="position relative">
                    <div className="position-absolute inverted-comma d-lg-none tablet-display-off">
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/inverted.png"
                        className="img-fluid"
                      ></img>
                    </div>
                    <div className="ps-5 ps-lg-0">
                      <h4 className="poppins-medium text-uppercase mb-4">
                        “One of the best e-bike manufacturer in the market! Have
                        consistently gotten e-bikes customized to my liking and
                        they never disappoint, 11/10 recommended!..........”
                      </h4>
                      <p className="poppins-medium mb-0">James Mill</p>
                    </div>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="d-flex p-lg-4" style={{ gap: "2em" }}>
                  <div className="w-25 d-none d-lg-block tablet-display-on">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/testimonial2.png"
                      className="rounded-0"
                    ></img>
                  </div>
                  <div className="position relative">
                    <div className="position-absolute inverted-comma d-lg-none tablet-display-off">
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/inverted.png"
                        className="img-fluid"
                      ></img>
                    </div>
                    <div className="ps-5 ps-lg-0">
                      <h4 className="poppins-medium text-uppercase mb-4">
                        Switching to Dynem was a game-changer for us! The entire
                        process, from sourcing to delivery, was seamless. Plus,
                        the savings we achieved by moving our manufacturing to
                        India were substantial. Highly recommend!"
                      </h4>
                      <p className="poppins-medium mb-0">Jake Severisson</p>
                    </div>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="d-flex p-lg-4" style={{ gap: "2em" }}>
                  <div className="w-25 d-none d-lg-block tablet-display-on">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/testimonial3.png"
                      className="rounded-0"
                    ></img>
                  </div>
                  <div className="position relative">
                    <div className="position-absolute inverted-comma d-lg-none tablet-display-off">
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/inverted.png"
                        className="img-fluid"
                      ></img>
                    </div>
                    <div className="ps-5 ps-lg-0">
                      <h4 className="poppins-medium text-uppercase mb-4">
                        "Working with Dynem has been nothing short of excellent.
                        The transition from China to India was incredibly
                        smooth, and the cost savings we realized were
                        significant. Great partner for ebike manufacturing!"
                      </h4>
                      <p className="poppins-medium mb-0">Carolina Spike</p>
                    </div>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="d-flex p-lg-4" style={{ gap: "2em" }}>
                  <div className="w-25 d-none d-lg-block tablet-display-on">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/testimonial4.png"
                      className="rounded-0"
                    ></img>
                  </div>
                  <div className="position relative">
                    <div className="position-absolute inverted-comma d-lg-none tablet-display-off">
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/inverted.png"
                        className="img-fluid"
                      ></img>
                    </div>
                    <div className="ps-5 ps-lg-0">
                      <h4 className="poppins-medium text-uppercase mb-4">
                        "Dynem made our shift to Indian manufacturing
                        effortless. Their attention to detail and commitment to
                        quality were impressive, and the cost benefits were
                        immediate. We’re very satisfied with the move!
                      </h4>
                      <p className="poppins-medium mb-0">Kevin Spearson</p>
                    </div>
                  </div>
                </div>
              </SplideSlide>
            </Splide>
          </div>
        </div>
        {/* news */}
        <div className="news">
          <div className="container mt-5 py-4 py-lg-5">
            <h2 className="poppins-medium mb-3 mb-lg-5">In the news</h2>
            <Splide
              className="mb-2 mb-lg-0 p-0 align-items-center"
              options={{
                arrows: false,
                drag: false,
                type: "slide",
                pagination: false,
                autoplay: false,
                perMove: 1,
                gap: "1em",
                padding: "0em",
                pauseOnHover: true,
                resetProgress: false,
                perPage: 4,
                speed: 1000,
                interval: 3000,
                breakpoints: {
                  700: {
                    drag: true,
                    perPage: 1.3,
                    arrows: false,
                    perMove: 1,
                    gap: "1em",
                    padding: "0em",
                    pauseOnHover: false,
                    resetProgress: false,
                    focus: 0,
                    start: 0,
                    speed: 1000,
                    interval: 3000,
                  },
                },
              }}
              muted={true}
            >
              <SplideSlide className="h-100">
                <div className="p-3" style={{ backgroundColor: "white" }}>
                  <div>
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/News+1.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/kxan.png"
                    className="img-fluid mt-2"
                  ></img>
                  <p className="my-3 news-heading poppins-medium">
                    DYNEM begins construction of World's Biggest Integrated
                    Electric Cycle Gigafactory.....
                  </p>
                  <small>May 09, 2024</small>
                  <div
                    className="w-100 text-center py-2 mt-2"
                    style={{
                      backgroundColor: "black",
                      minHeight: "max-content",
                    }}
                  >
                    <a
                      className="text-light text-decoration-none"
                      href="https://www.kxan.com/business/press-releases/cision/20240509IO08933/dynem-begins-construction-of-worlds-biggest-integrated-electric-cycle-gigafactory-in-india/"
                    >
                      Read More
                    </a>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-3" style={{ backgroundColor: "white" }}>
                  <div>
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/News+2.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/ktla.png"
                    className="img-fluid mt-2"
                  ></img>
                  <p className="my-3 news-heading poppins-medium">
                    India’s DYNEM to Establish World’s Largest Integrated E-Bike
                    Gigafactory
                  </p>
                  <small>May 09, 2024</small>
                  <div
                    className="w-100 text-center py-2 mt-2"
                    style={{
                      backgroundColor: "black",
                      minHeight: "max-content",
                    }}
                  >
                    <a
                      className="text-light text-decoration-none"
                      href="https://fox59.com/business/press-releases/cision/20240509IO08933/dynem-begins-construction-of-worlds-biggest-integrated-electric-cycle-gigafactory-in-india/"
                    >
                      Read More
                    </a>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-3" style={{ backgroundColor: "white" }}>
                  <div>
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/News+3.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/benzinga.png"
                    className="img-fluid mt-2"
                  ></img>
                  <p className="my-3 news-heading poppins-medium">
                    DYNEM Breaks Ground on World’s Largest Integrated Electric
                    Cycle Gigafactory ...
                  </p>
                  <small>May 09, 2024</small>
                  <div
                    className="w-100 text-center py-2 mt-2"
                    style={{
                      backgroundColor: "black",
                      minHeight: "max-content",
                    }}
                  >
                    <a
                      className="text-light text-decoration-none"
                      href="https://www.benzinga.com/pressreleases/24/05/n38730936/dynem-begins-construction-of-worlds-biggest-integrated-electric-cycle-gigafactory-in-india"
                    >
                      Read More
                    </a>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-3" style={{ backgroundColor: "white" }}>
                  <div>
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/News+4.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/fox8.png"
                    className="img-fluid mt-2"
                  ></img>
                  <p className="my-3 news-heading poppins-medium">
                    DYNEM Strengthens India’s Manufacturing Prowess: World’s
                    Biggest .....
                  </p>
                  <small>May 09, 2024</small>
                  <div
                    className="w-100 text-center py-2 mt-2"
                    style={{
                      backgroundColor: "black",
                      minHeight: "max-content",
                    }}
                  >
                    <a
                      className="text-light text-decoration-none"
                      href="https://www.benzinga.com/pressreleases/24/05/n38730936/dynem-begins-construction-of-worlds-biggest-integrated-electric-cycle-gigafactory-in-india"
                    >
                      Read More
                    </a>
                  </div>
                </div>
              </SplideSlide>
            </Splide>
          </div>
        </div>
        {/* FAQs */}
        <div className="container mt-5 pt-lg-5">
          <div className="d-flex faq-landing">
            <div className="faq-landing-1">
              <h2 className="poppins-medium mb-2 mb-lg-4">
                Frequently asked questions
              </h2>
              <p className="mb-lg-5">
                Got questions? We’ve already got answers. It’s like we can hear
                you thinking.
              </p>
              <div className="d-lg-block d-none tablet-display-on">
                <h4 className="poppins-medium mb-2">Did we miss something?</h4>
                <p className="mb-lg-5">
                  Get on a one on one call with our experts.
                </p>
                <div className="req-call my-5 my-lg-0">
                  <a
                    href="tel:+918956703515"
                    className="btn btn-dark px-5 d-flex justify-content-between rounded-0"
                    style={{ width: "fit-content" }}
                  >
                    Request a Call
                  </a>
                </div>
              </div>
            </div>
            <div className="accordion faq-landing-2" id="accordionExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    What makes DYNEM different from other e-bike manufacturers?
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body poppins-thin">
                    <p>
                      DYNEM stands out for its cutting-edge innovation,
                      eco-friendly practices, and extensive customization
                      options. Our state-of-the-art gigafactory, global
                      compliance, and expert team ensure top-notch quality and
                      seamless support from production to after-sales, leaving
                      you with nothing but peace of mind.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Can I customise my e-bike with DYNEM?
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                      Absolutely! DYNEM offers extensive customization options,
                      allowing you to tailor your e-bike to your specific style
                      and needs. Our OEM service lets you customise branding,
                      specifications, and features to match your preferences.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    How many e-bikes does DYNEM produce annually?
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                      DYNEM has the capacity to produce and customise 500,000
                      e-bikes annually. Our efficient production processes and
                      world-class infrastructure enable us to meet growing
                      global demand.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingFour">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    What are the benefits of DYNEM's anti-dumping solutions?
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                      DYNEM's legal anti-dumping solutions allow importers to
                      save up to 87% in taxes. Our products comply with global
                      regulations, ensuring a level playing field without any
                      anti-dumping taxes. This makes DYNEM a cost-effective and
                      compliant choice for importers worldwide.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingfive">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    How does DYNEM support sustainability?
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFive"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                      DYNEM is committed to sustainability through eco-friendly
                      practices and innovative technology. Our e-bikes are
                      designed to reduce carbon footprints and promote a greener
                      future, aligning with our vision of sustainable mobility.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingSix">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    What kind of after-sales support does DYNEM provide?
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingSix"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                      DYNEM prioritises customer satisfaction with dedicated
                      after-sales support. From the initial purchase to
                      long-term maintenance, our team is committed to providing
                      seamless and reliable assistance, ensuring a smooth and
                      satisfying experience for our customers.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-lg-none text-center mt-3 tablet-display-off">
              <h4 className="poppins-medium mb-2">Did we miss something?</h4>
              <p className="mb-lg-5">
                Get on a one on one call with our experts.
              </p>
              <div className="req-call my-4">
                <a
                  href="tel:+918956703515"
                  className="btn btn-dark px-5 d-flex justify-content-between rounded-0"
                  style={{ width: "fit-content" }}
                >
                  Request a Call
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="d-lg-block d-none">
          <div className="d-flex justify-content-center align-items-center mt-4 mt-lg-5">
            <Modal className="primary-button fw-bold px-4 py-2" />
          </div>
        </div>

        {/* contact us */}
        <div className="mt-5 py-lg-5" style={{ backgroundColor: "black" }}>
          <div className="container py-lg-5 py-4">
            <div className="d-flex mb-3 mb-lg-0" style={{ gap: "4em" }}>
              <div className="d-none d-lg-block w-50">
                <div className="row">
                  <div className="col-6 p-0">
                    <div className="d-flex justify-content-end">
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/grid1.png"
                        className="w-75 p-3"
                      ></img>
                    </div>
                    <div className="d-flex justify-content-end">
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/grid5.png"
                        className="w-100 p-3"
                      ></img>
                    </div>
                  </div>
                  <div className="col-6 p-0">
                    <div className="d-flex justify-content-start mt-5">
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/grid2.png"
                        className="w-100 p-3"
                      ></img>
                    </div>
                    <div className="d-flex justify-content-start">
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/grid4.png"
                        className="w-75 p-3"
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-none d-lg-block">
                <h2 className="poppins-medium mb-4 text-light">
                  Let’s Connect
                </h2>
                <form className="contact-us-landing" onSubmit={submit}>
                  <div class="mb-3 d-flex" style={{ gap: "1em" }}>
                    <div className="w-50">
                      <label
                        for="exampleInputEmail1"
                        className="form-label text-light"
                      >
                        Full Name*
                      </label>
                      <input
                        type="text"
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                        required
                        class="form-control rounded-0"
                        aria-describedby="emailHelp"
                      />
                    </div>
                    <div className="w-50">
                      <label
                        for="exampleInputEmail1"
                        className="form-label text-light"
                      >
                        Country*
                      </label>
                      <input
                        type="text"
                        value={country}
                        onChange={(e) => {
                          setCountry(e.target.value);
                        }}
                        required
                        class="form-control rounded-0"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  </div>
                  <div class="mb-3 d-flex" style={{ gap: "1em" }}>
                    <div class="w-50">
                      <label
                        for="exampleInputPassword1"
                        class="form-label text-light"
                      >
                        Contact No*
                      </label>
                      <PhoneInput
                        value={phoneNumber}
                        onChange={setPhoneNumber}
                        required
                        defaultCountry="IN"
                        className="form-control w-100 rounded-0"
                      />
                    </div>
                    <div className="w-50">
                      <label
                        for="exampleInputEmail1"
                        className="form-label text-light"
                      >
                        Work Email*
                      </label>
                      <input
                        type="email"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        required
                        class="form-control rounded-0"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  </div>

                  <div class="mb-4 d-flex" style={{ gap: "1em" }}>
                    <div className="w-100">
                      <label
                        for="exampleInputEmail1"
                        className="form-label text-light"
                      >
                        Organisation*
                      </label>
                      <input
                        type="text"
                        value={companyName}
                        required
                        onChange={(e) => {
                          setCompanyName(e.target.value);
                        }}
                        class="form-control rounded-0"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label
                      for="exampleInputEmail1"
                      className="form-label text-light"
                    >
                      Post Your Enquiry*
                    </label>
                    <textarea
                      class="form-control rounded-0"
                      required
                      id="exampleFormControlTextarea1"
                      rows="3"
                      value={enquiry}
                      onChange={(e) => {
                        setEnquiry(e.target.value);
                      }}
                    ></textarea>
                  </div>
                  <button
                    type="submit"
                    class="btn btn-light w-100 poppins-medium rounded-0"
                  >
                    Get Quote
                  </button>
                </form>
              </div>
            </div>
            <div className="d-lg-none mb-5">
              <h2 className="poppins-medium mb-4 text-light">Let’s Connect</h2>
              <form className="contact-us-landing" onSubmit={submit}>
                <div class="mb-3 d-flex" style={{ gap: "1em" }}>
                  <div className="w-50">
                    <label
                      for="exampleInputEmail1"
                      className="form-label text-light"
                    >
                      Full Name*
                    </label>
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      required
                      class="form-control rounded-0"
                      aria-describedby="emailHelp"
                    />
                  </div>
                  <div className="w-50">
                    <label
                      for="exampleInputEmail1"
                      className="form-label text-light"
                    >
                      Country*
                    </label>
                    <input
                      type="text"
                      value={country}
                      onChange={(e) => {
                        setCountry(e.target.value);
                      }}
                      required
                      class="form-control rounded-0"
                      aria-describedby="emailHelp"
                    />
                  </div>
                </div>
                <div class="mb-3 d-flex" style={{ gap: "1em" }}>
                  <div class="w-50">
                    <label
                      for="exampleInputPassword1"
                      class="form-label text-light"
                    >
                      Contact No*
                    </label>
                    <PhoneInput
                      value={phoneNumber}
                      onChange={setPhoneNumber}
                      required
                      defaultCountry="IN"
                      className="form-control w-100 rounded-0"
                    />
                  </div>
                  <div className="w-50">
                    <label
                      for="exampleInputEmail1"
                      className="form-label text-light"
                    >
                      Work Email*
                    </label>
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      required
                      class="form-control rounded-0"
                      aria-describedby="emailHelp"
                    />
                  </div>
                </div>

                <div class="mb-4 d-flex" style={{ gap: "1em" }}>
                  <div className="w-100">
                    <label
                      for="exampleInputEmail1"
                      className="form-label text-light"
                    >
                      Organisation*
                    </label>
                    <input
                      type="text"
                      value={companyName}
                      required
                      onChange={(e) => {
                        setCompanyName(e.target.value);
                      }}
                      class="form-control rounded-0"
                      aria-describedby="emailHelp"
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <label
                    for="exampleInputEmail1"
                    className="form-label text-light"
                  >
                    Post Your Enquiry*
                  </label>
                  <textarea
                    class="form-control rounded-0"
                    id="exampleFormControlTextarea1"
                    required
                    rows="3"
                    value={enquiry}
                    onChange={(e) => {
                      setEnquiry(e.target.value);
                    }}
                  ></textarea>
                </div>
                <button
                  type="submit"
                  class="btn btn-light w-100 poppins-medium rounded-0"
                >
                  Get Quote
                </button>
              </form>
            </div>
            <div className="row p-2 d-lg-none">
              <div className="col-6 p-0">
                <div className="d-flex justify-content-end">
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/grid1.png"
                    className="w-75 p-2"
                  ></img>
                </div>
                <div className="d-flex justify-content-end">
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/grid5.png"
                    className="w-100 p-2"
                  ></img>
                </div>
              </div>
              <div className="col-6 p-0">
                <div className="d-flex justify-content-start mt-5">
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/grid2.png"
                    className="w-100 p-2"
                  ></img>
                </div>
                <div className="d-flex justify-content-start">
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/grid4.png"
                    className="w-75 p-2"
                  ></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default GetQuoteLanding;
