import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import "../css/landingPage.css";
import cst from "../images/partnerLogos/cst.png";
import kenli from "../images/partnerLogos/kenli.png";
import kmc from "../images/partnerLogos/kmc.png";
import maxxis from "../images/partnerLogos/maxxis.png";
import wtb from "../images/partnerLogos/wtb.png";
import schwalbe from "../images/partnerLogos/schwalbe.png";
import neco from "../images/partnerLogos/neco.png";
import ralson from "../images/partnerLogos/ralson.png";
import prowheel from "../images/partnerLogos/prowheel.png";
import sunrace from "../images/partnerLogos/sunrace.png";
import shimano from "../images/partnerLogos/shimano.png";
import sram from "../images/partnerLogos/sram.png";
import rockshox from "../images/partnerLogos/rockshox.png";
import microshift from "../images/partnerLogos/microshift.png";
import axios from "axios";
import PhoneInput from "react-phone-number-input";
import Meta from "../components/Meta";
import Modal from "../components/Modal";
import ModalMobile from "../components/ModalMobile";
import ReactPlayer from "react-player";
import CustomBike from "../components/CustomBike";
import "../css/navbar.css";
import logo from "../images/brandLogos/Dynem red white.png";
import { Icon } from "@iconify/react";

const GetQuoteItaly = () => {
  const featuresData = [
    {
      key: "precisionControllers",
      heading: "Controller di precisione",
      description:
        "I controller di una e-bike utilizzano sensori e algoritmi per regolare l'erogazione di potenza in modo fluido e il suo sistema intelligente migliora le prestazioni e rende l'e-bike più intuitiva e facile da usare.",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/Controlerrrr.png",
    },
    {
      key: "highPerformanceChargers",
      heading: "Caricabatterie ad alte prestazioni",
      description:
        "I caricabatterie sono disponibili in una gamma di opzioni personalizzabili che soddisfano le preferenze, in base alla compatibilità globale, alla portabilità o alla flessibilità di alimentazione estesa.",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/Chargerrrr.png",
    },
    {
      key: "multifunctionalDisplays",
      heading: "Display multifunzionali",
      description:
        "Questi display LCD offrono dati in tempo reale come velocità e batteria che consentono ai ciclisti di monitorare e regolare le proprie modalità di guida.",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/Displayyyyy.png",
    },
    {
      key: "longLastingBatteries",
      heading: "Batterie a lunga durata",
      description:
        "Questo sistema elettrico immagazzina energia e alimenta il motore elettrico. Questa tecnologia è stata progettata per garantire prestazioni costanti, una maggiore durata della batteria e un'erogazione di potenza ottimale, migliorando l'esperienza di guida complessiva.",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/Baterryyyyy.png",
    },
    {
      key: "efficientMotors",
      heading: "Motori efficienti",
      description:
        "I nostri motori versatili e innovativi sono stati progettati per offrire prestazioni affidabili e precise, garantendo un'utilità ottimale in varie applicazioni.",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/motorrrr.png",
    },
  ];
  const [activeFeature1, setActiveFeature1] = useState("precisionControllers");
  const handleFeature1Click = (feature) => {
    setActiveFeature1(feature.key);
  };
  const getActiveFeature1 = () => {
    return featuresData.find((feature) => feature.key === activeFeature1);
  };
  const activeFeatureObj1 = getActiveFeature1();
  const [open, openNav] = useState(false);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  }, []);

  const closeNavforDeadLink = () => {
    openNav(false);
  };

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [country, setCountry] = useState("");
  const [enquiry, setEnquiry] = useState("");

  const componentData = [
    {
      key: "Frame",
    },
    {
      key: "Motor",
    },
    {
      key: "Derailleur",
    },
    {
      key: "Battery",
    },
    {
      key: "Display",
    },
  ];
  const [activeComponent, setActiveComponent] = useState("Frame");
  const handleComponentClick = (feature) => {
    setActiveComponent(feature.key);
  };

  const navigate = useNavigate();

  const submit = async (e) => {
    e.preventDefault();
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const reqBody = {
      name,
      email,
      phoneNumber,
      companyName,
      country,
      enquiry,
    };
    const { data } = await axios.post(
      "/api/form/request-a-quote",
      reqBody,
      config
    );
    if (data) {
      navigate("/details-submitted");
    }
  };
  return (
    <>
      {/* landing page main banner */}
      <Meta
        title={"Get a Quote - Get Wholesale E-bike Purchase Quote from Dynem"}
        description={
          "Get a wholesale e-bike purchase quote from Dynem, a leading e-bike manufacturer and supplier. Request your personalized quote today for bulk orders."
        }
        cannonicalTag={"https://www.dynem.in/get-quote/it"}
      />
      {/* desktop navbar */}
      <div
        className="position-sticky w-100 navbar"
        style={{ zIndex: "99", top: "0%" }}
      >
        <div className="row container mx-auto px-3 px-lg-0">
          <div className="col-12 col-md-4 d-flex justify-content-start p-0">
            <Link to={"/"}>
              <img src={logo} alt="dynem-logo" className="logo"></img>
            </Link>
          </div>
          <div className="col-5 d-flex nav-items p-0"></div>
          <div className="col-3 p-0 d-none d-lg-block d-md-block">
            <div className="d-flex justify-content-end align-items-center">
              <Modal
                language={"italy"}
                className="primary-button fw-bold px-4 py-2"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="get-quote-landing">
        {/* hero-banner */}
        <div className="hero-banner position-relative">
          <picture className="main-banner-img">
            <source
              media="(max-width:650px)"
              srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/banners/heroBanner_mobile.jpg"
            />
            <source
              media="(min-width:651px)"
              srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/banners/heroBanner.jpg"
            />
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/banners/heroBanner.jpg"
              alt="hero-banner"
              className="d-block w-100"
            />
          </picture>
          <div
            className="d-none d-lg-block position-absolute"
            style={{ top: "10%", left: "7%" }}
          >
            <h1
              className="poppins-bold text-light"
              style={{ fontSize: "3.5em" }}
            >
              <span style={{ color: "#ff0000" }}>POTENZIA LA</span>
              <br></br>
              TUA PRODUZIONE DI E-BIKE
            </h1>
            <p className="text-light w-50">
              Migliora la produzione della tua e-bike con l'ingegneria di
              precisione e la maestria artigianale di Dynem, offrendo risultati
              superiori che distinguono il tuo marchio.
            </p>
          </div>
          <div className="d-lg-none position-absolute" style={{ top: "0%" }}>
            <div className="d-flex justify-content-center w-100 p-4">
              <h1
                className="poppins-bold text-light"
                style={{ fontSize: "2.5em" }}
              >
                <span style={{ color: "#ff0000" }}>POTENZIA LA</span>
                <br></br>
                TUA PRODUZIONE DI E-BIKE
              </h1>
            </div>
          </div>
          <div
            className="d-lg-block d-none position-absolute"
            style={{
              bottom: "10%",
              left: "7%",
              backgroundColor: "rgba(151, 151, 151, 0.56)",
            }}
          >
            <div
              className="d-flex p-3 px-5 text-center poppins-medium"
              style={{
                gap: "2em",
              }}
            >
              <h5 className="text-light">
                5,00,000+<br></br>Capacità della bici elettrica
              </h5>
              <h5 className="text-light">
                4,00,000+<br></br>Capacità dei componenti
              </h5>
              <h5 className="text-light">
                Treno di<br></br>guida interno
              </h5>
              <Modal
                language={"italy"}
                className="primary-button fw-bold px-4 py-2"
              />
            </div>
          </div>
          <div
            className="d-lg-none position-absolute w-100"
            style={{
              bottom: "10%",
              backgroundColor: "rgba(151, 151, 151, 0.56)",
            }}
          >
            <div
              className="d-flex p-3 text-center poppins-medium"
              style={{
                gap: "1.7em",
                fontSize: "0.8em",
              }}
            >
              <h5 className="text-light">
                5,00,000+<br></br>Capacità della bici elettrica
              </h5>
              <h5 className="text-light">
                4,00,000+<br></br>Capacità dei componenti
              </h5>
              <h5 className="text-light">
                Treno di<br></br>guida interno
              </h5>
            </div>
          </div>
          <div className="d-lg-none get-quote-banner-button-mb position-absolute">
            <div className="d-flex" style={{ gap: "1.5em" }}>
              <ModalMobile
                language={"italy"}
                className="primary-button fw-bold px-4 py-2"
              />
            </div>
          </div>
        </div>
        {/* world class products */}
        <div className="">
          <div className=" mt-5 py-4 py-lg-5">
            <h2 className="poppins-medium mb-3 mb-lg-5 text-center container">
              Produzione di classe, dall'India, per il mondo!
            </h2>
            <div className="news py-5">
              <Splide
                className="mb-2 mb-lg-0 p-0 align-items-center container"
                options={{
                  arrows: false,
                  drag: false,
                  type: "slide",
                  pagination: false,
                  autoplay: false,
                  perMove: 1,
                  gap: "2em",
                  padding: "0em",
                  pauseOnHover: true,
                  resetProgress: false,
                  perPage: 4,
                  speed: 1000,
                  interval: 3000,
                  breakpoints: {
                    700: {
                      drag: true,
                      perPage: 1.3,
                      arrows: false,
                      perMove: 1,
                      gap: "1em",
                      padding: "0em",
                      pauseOnHover: false,
                      resetProgress: false,
                      focus: 0,
                      start: 0,
                      speed: 1000,
                      interval: 3000,
                    },
                  },
                }}
                muted={true}
              >
                <SplideSlide className="h-100">
                  <div className="p-3" style={{ backgroundColor: "white" }}>
                    <div>
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/products/ecycle.jpg"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <h5 className="poppins-medium mt-3 text-center">
                      Bici elettriche
                    </h5>
                  </div>
                </SplideSlide>
                <SplideSlide className="h-100">
                  <div className="p-3" style={{ backgroundColor: "white" }}>
                    <div>
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/products/motor.jpg"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <h5 className="poppins-medium mt-3 text-center">Motore</h5>
                  </div>
                </SplideSlide>
                <SplideSlide className="h-100">
                  <div className="p-3" style={{ backgroundColor: "white" }}>
                    <div>
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/products/battery.jpg"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <h5 className="poppins-medium mt-3 text-center">
                      Batteria
                    </h5>
                  </div>
                </SplideSlide>
                <SplideSlide className="h-100">
                  <div className="p-3" style={{ backgroundColor: "white" }}>
                    <div>
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/products/dispaly.jpg"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <h5 className="poppins-medium mt-3 text-center">Display</h5>
                  </div>
                </SplideSlide>
              </Splide>
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center py-4">
            <Modal
              language={"italy"}
              className="primary-button fw-bold px-4 py-2 d-lg-block d-none"
            />
            <ModalMobile
              language={"italy"}
              className="primary-button fw-bold px-4 py-2 d-lg-none"
            />
          </div>
        </div>
        {/* company logos */}
        <div className="container my-5 py-lg-5 d-none d-lg-block">
          <Splide
            className="flex align-items-center"
            extensions={{ AutoScroll }}
            options={{
              type: "loop",
              autoScroll: {
                speed: 1,
                pauseOnHover: false,
              },
              perPage: 6,
              drag: "free",
              arrows: false,
              pagination: false,
              pauseOnHover: false,
              gap: "3em",
              padding: "1em",
              breakpoints: {
                640: {
                  perPage: 3,
                  gap: "1em",
                },
              },
            }}
          >
            <SplideSlide className="h-100">
              <div>
                <img src={shimano} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={kmc} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={maxxis} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={wtb} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={rockshox} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={neco} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={ralson} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={sunrace} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={microshift} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={sram} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={schwalbe} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
          </Splide>
        </div>
        <div className="my-5 py-lg-5 d-lg-none">
          <Splide
            className="flex align-items-center"
            extensions={{ AutoScroll }}
            options={{
              type: "loop",
              autoScroll: {
                speed: 1,
                pauseOnHover: false,
              },
              perPage: 6,
              drag: "free",
              arrows: false,
              pagination: false,
              pauseOnHover: false,
              gap: "3em",
              padding: "1em",
              breakpoints: {
                640: {
                  perPage: 3,
                  gap: "1em",
                },
              },
            }}
          >
            <SplideSlide className="h-100">
              <div>
                <img src={shimano} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={kmc} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={maxxis} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={wtb} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={rockshox} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={neco} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={ralson} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={sunrace} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={microshift} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={sram} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={schwalbe} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
          </Splide>
        </div>
        {/* why choose us */}
        <div className="news">
          <div className="container mt-5 pt-lg-5 pt-5">
            <h2 className="poppins-medium mb-2">Il tuo partner nel successo</h2>
            <h4 className="">Ecco perché ci distinguiamo</h4>
            <div className="py-5">
              <div className="d-flex flex-wrap justify-content-between text-center w-100 mb-3 mb-lg-5 why-choose-us">
                <div className="flex-item">
                  <div className="d-flex justify-content-center mb-3">
                    <img
                      className="img-fluid"
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/production.png"
                    ></img>
                  </div>

                  <p className="m-0 poppins-medium">
                    500,000<br></br>Capacità produttiva
                  </p>
                </div>
                <div className="flex-item">
                  <div className="d-flex justify-content-center mb-3">
                    <img
                      className="img-fluid"
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/component.png"
                    ></img>
                  </div>
                  <p className="m-0 poppins-medium">
                    400,000<br></br>Capacità di componenti
                  </p>
                </div>
                <div className="flex-item">
                  <div className="d-flex justify-content-center mb-3">
                    <img
                      className="img-fluid"
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/cost.png"
                    ></img>
                  </div>
                  <p className="m-0 poppins-medium">
                    87%<br></br>Benefici in termini di costi
                  </p>
                </div>
                <div className="flex-item">
                  <div className="d-flex justify-content-center mb-3">
                    <img
                      className="img-fluid"
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/iot.png"
                    ></img>
                  </div>
                  <p className="m-0 poppins-medium">
                    IOT abilitato<br></br>Soluzioni
                  </p>
                </div>
              </div>
              <div className="d-flex flex-wrap flex-wrap-2 justify-content-between text-center w-100 why-choose-us">
                <div className="flex-item">
                  <div className="d-flex justify-content-center mb-3">
                    <img
                      className="img-fluid"
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/certificate.png"
                    ></img>
                  </div>

                  <p className="mt-2 poppins-medium">
                    Europa<br></br>Certificazioni
                  </p>
                </div>
                <div className="flex-item">
                  <div className="d-flex justify-content-center mb-3">
                    <img
                      className="img-fluid"
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/in-stock.png"
                    ></img>
                  </div>
                  <p className="mt-2 poppins-medium">
                    Scorta pronta<br></br>disponibile
                  </p>
                </div>
                <div className="flex-item">
                  <div className="d-flex justify-content-center mb-3">
                    <img
                      className="img-fluid"
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/automation.png"
                    ></img>
                  </div>
                  <p className="mt-2 poppins-medium">
                    Tutti i componenti<br></br>produzione interna
                  </p>
                </div>
                <div className="flex-item">
                  <div className="d-flex justify-content-center mb-3">
                    <img
                      className="img-fluid"
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/container.png"
                    ></img>
                  </div>
                  <p className="mt-2 poppins-medium">
                    Antidumping legale<br></br>Soluzione
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* range of dts */}
        <div className="container mt-5 pt-4 pt-lg-5">
          <h2 className="poppins-medium mb-4 mb-lg-5">
            Componenti che alimentano ogni chilometro
          </h2>
          <div className="d-lg-none pt-4">
            <Splide
              className="mb-5 p-0 align-items-center"
              options={{
                drag: true,
                perPage: 1.2,
                arrows: false,
                pagination: false,
                autoplay: false,
                perMove: 1,
                interval: 4000,
                gap: "1em",
                padding: "0em",
                pauseOnHover: false,
                resetProgress: false,
                focus: 0,
                start: 0,
                speed: 500,
              }}
            >
              {featuresData.slice(0, 4).map((feature) => (
                <SplideSlide className="feature-btn-width">
                  <button
                    key={feature}
                    className={
                      feature.key === activeFeature1
                        ? "em-btn-1 rounded-0"
                        : "em-btn-2 rounded-0"
                    }
                    onClick={() => handleFeature1Click(feature)}
                  >
                    {feature.heading}
                  </button>
                </SplideSlide>
              ))}
            </Splide>
          </div>
          <div className="mb-5 d-none d-lg-block">
            <div
              className="d-flex justify-content-center mb-3"
              style={{ gap: "1em" }}
            >
              {featuresData.map((feature) => (
                <button
                  key={feature}
                  className={
                    feature.key === activeFeature1
                      ? "em-btn-1 rounded-0"
                      : "em-btn-2 rounded-0"
                  }
                  onClick={() => handleFeature1Click(feature)}
                >
                  {feature.heading}
                </button>
              ))}
            </div>
          </div>
          <div className="d-none d-lg-block my-5 px-5">
            <div className="d-flex align-items-center justify-content-center">
              <div className="feature-text w-100">
                <h1>{activeFeatureObj1.heading}</h1>{" "}
                <p>{activeFeatureObj1.description}</p>
              </div>
              <div className="w-100">
                <img
                  src={activeFeatureObj1.image}
                  alt="amigo-feature"
                  className="img-fluid"
                ></img>
              </div>
            </div>
          </div>
          <div className="row mt-5 d-lg-none">
            <div className="col-12 col-lg-6 feature-text">
              <h1>{activeFeatureObj1.heading}</h1>{" "}
              <p>{activeFeatureObj1.description}</p>
            </div>
            <div className="col-12 col-lg-6">
              <div className="d-flex d-lg-block justify-content-center">
                <img
                  src={activeFeatureObj1.image}
                  alt="amigo-feature"
                  className="img-fluid mx-auto"
                ></img>
              </div>
            </div>
          </div>
        </div>
        {/* range of e-bikes */}
        <div className="mt-5 pt-lg-5 ">
          <div className="position-relative">
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/banners/breaker2.jpg"
              className="img-fluid"
            ></img>
            <div
              className="position-absolute d-lg-block d-none"
              style={{ top: "25%", left: "58%" }}
            >
              <div>
                <h1
                  className="poppins-medium text-light"
                  style={{ fontSize: "5em" }}
                >
                  Esplora la nostra<br></br>flotta elettrica
                </h1>
              </div>
            </div>
          </div>
          <h2 className="poppins-medium d-lg-none my-4 text-center">
            Esplora la nostra flotta elettrica
          </h2>
          <div className=" py-3 d-lg-none">
            <Splide
              className="mb-2 mb-lg-0 p-0 align-items-center container"
              options={{
                arrows: false,
                drag: false,
                type: "slide",
                pagination: false,
                autoplay: false,
                perMove: 1,
                gap: "2em",
                padding: "0em",
                pauseOnHover: true,
                resetProgress: false,
                perPage: 4,
                speed: 1000,
                interval: 3000,
                breakpoints: {
                  700: {
                    drag: true,
                    perPage: 1.3,
                    arrows: false,
                    perMove: 1,
                    gap: "1em",
                    padding: "0em",
                    pauseOnHover: false,
                    resetProgress: false,
                    focus: 0,
                    start: 0,
                    speed: 1000,
                    interval: 3000,
                  },
                },
              }}
              muted={true}
            >
              <SplideSlide className="h-100">
                <div className="p-3 news">
                  <div>
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/ebikes/mountain.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="mt-3 text-center" style={{ height: "40px" }}>
                    E-Bike da montagna
                  </h4>
                  <div style={{ height: "160px" }}>
                    <p className="text-center m-0">
                      Non siamo solo un produttore di biciclette elettriche da
                      montagna; siamo pionieri nell'avventura fuoristrada.
                      Esplora una gamma di e-bike realizzate meticolosamente per
                      terreni accidentati e corse emozionanti.
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-3 news">
                  <div>
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/ebikes/fat-tyre.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="mt-3 text-center" style={{ height: "40px" }}>
                    E-Bike pieghevoli e con pneumatici grassi
                  </h4>
                  <div style={{ height: "160px" }}>
                    <p className="text-center m-0">
                      In qualità di produttore di eBike pieghevoli di alto
                      livello, DYNEM ti offre la miscela definitiva di praticità
                      e stile. Le nostre e-bike pieghevoli sono perfette per gli
                      esploratori della città e i personaggi del jet set.
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-3 news">
                  <div>
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/ebikes/city.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="mt-3 text-center" style={{ height: "40px" }}>
                    Bici elettriche da città
                  </h4>
                  <div style={{ height: "160px" }}>
                    <p className="text-center m-0">
                      In qualità di produttore leader di biciclette da città,
                      DYNEM ridefinisce il pendolarismo urbano con corse
                      eleganti, efficienti e alla moda. Le nostre city bike
                      uniscono tecnologia all'avanguardia e design moderno,
                      assicurandoti di scivolare nel traffico con facilità.
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-3 news">
                  <div>
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/ebikes/cargo.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="mt-3 text-center" style={{ height: "40px" }}>
                    Consegna biciclette elettriche
                  </h4>
                  <div style={{ height: "160px" }}>
                    <p className="text-center m-0">
                      In qualità di produttore leader di biciclette per
                      consegne, DYNEM offre prestazioni e affidabilità di alto
                      livello per ogni consegna. Le nostre e-bike sono
                      progettate per le esigenze di logistica urbana, motori
                      potenti e ampio spazio di carico.
                    </p>
                  </div>
                </div>
              </SplideSlide>
            </Splide>
          </div>
          <div className="range-ebikes container d-lg-block d-none py-5 my-5">
            <Splide
              className="mb-2 mb-lg-0 p-0 align-items-center text-center"
              options={{
                arrows: true,
                start: 0,
                drag: false,
                type: "loop",
                pagination: true,
                autoplay: true,
                gap: "3em",
                padding: "1em",
                pauseOnHover: true,
                resetProgress: false,
                perPage: 1,
                speed: 1000,
                interval: 4000,
                breakpoints: {
                  700: {
                    drag: true,
                    autoplay: false,
                    pagination: "false",
                    perPage: 1,
                    perMove: 1,
                    gap: "2em",
                    padding: "0em",
                    arrows: false,
                    pauseOnHover: false,
                    resetProgress: false,
                  },
                },
              }}
              muted={true}
            >
              <SplideSlide className="h-100">
                <div className="p-4 pt-0 p-lg-0 d-flex align-items-center ecycle-range-card">
                  <div className="">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/ebikes/mountain.png"
                      alt=""
                      className="w-100"
                    />
                  </div>
                  <div className="text-start p-3">
                    <h4 className="poppins-medium">E-Bike da montagna</h4>
                    <p className="">
                      Non siamo solo un produttore di biciclette elettriche da
                      montagna; siamo pionieri nell'avventura fuoristrada.
                      Esplora una gamma di e-bike realizzate meticolosamente per
                      terreni accidentati e corse emozionanti.
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-4 pt-0 p-lg-0 d-flex align-items-center ecycle-range-card">
                  <div className="">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/ebikes/fat-tyre.png"
                      alt=""
                      className="w-100"
                    />
                  </div>
                  <div className="text-start p-3">
                    <h4 className="poppins-medium">
                      E-Bike pieghevoli e con pneumatici grassi
                    </h4>
                    <p className="">
                      In qualità di produttore di eBike pieghevoli di alto
                      livello, DYNEM ti offre la miscela definitiva di praticità
                      e stile. Le nostre e-bike pieghevoli sono perfette per gli
                      esploratori della città e i personaggi del jet set.
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-4 pt-0 p-lg-0 d-flex align-items-center ecycle-range-card">
                  <div className="">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/ebikes/city.png"
                      alt=""
                      className="w-100"
                    />
                  </div>
                  <div className="text-start p-3">
                    <h4 className="poppins-medium">Bici elettriche da città</h4>
                    <p className="">
                      In qualità di produttore leader di biciclette da città,
                      DYNEM ridefinisce il pendolarismo urbano con corse
                      eleganti, efficienti e alla moda. Le nostre city bike
                      uniscono tecnologia all'avanguardia e design moderno,
                      assicurandoti di scivolare nel traffico con facilità.
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-4 pt-0 p-lg-0 d-flex align-items-center ecycle-range-card">
                  <div className="">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/ebikes/cargo.png"
                      alt=""
                      className="w-100"
                    />
                  </div>
                  <div className="text-start p-3">
                    <h4 className="poppins-medium">
                      Consegna biciclette elettriche
                    </h4>
                    <p className="">
                      In qualità di produttore leader di biciclette per
                      consegne, DYNEM offre prestazioni e affidabilità di alto
                      livello per ogni consegna. Le nostre e-bike sono
                      progettate per le esigenze di logistica urbana, motori
                      potenti e ampio spazio di carico.
                    </p>
                  </div>
                </div>
              </SplideSlide>
            </Splide>
          </div>
        </div>
        {/* certifications */}
        <div className="news mt-5 pt-lg-5 pt-4 mb-5">
          <h2 className="poppins-medium mb-3 mb-lg-4 container">
            Certificazioni
          </h2>
          <div className="container pb-4">
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/certifications__png.png"
              alt="certifications"
              className="w-100"
            ></img>
          </div>
        </div>
        <div className="container mt-5 pt-lg-5">
          <h2 className="poppins-medium mb-2">Fai il cambiamento</h2>
          <h4 className="mb-3 mb-lg-5">Semplifica la tua produzione con noi</h4>
          <div className="mx-lg-5 switching">
            <Splide
              className="mb-lg-0 p-0 align-items-center"
              options={{
                arrows: true,
                start: 0,
                drag: false,
                type: "loop",
                pagination: true,
                autoplay: true,
                gap: "3em",
                padding: "0em",
                pauseOnHover: true,
                resetProgress: false,
                perPage: 4,
                perMove: 1,
                speed: 1000,
                interval: 4000,
                breakpoints: {
                  700: {
                    drag: true,
                    arrows: false,
                    autoplay: false,
                    perPage: 1,
                    perMove: 1,
                    gap: "0em",
                    padding: "0em",
                    pauseOnHover: false,
                    resetProgress: false,
                  },
                },
              }}
              muted={true}
            >
              <SplideSlide className="h-100 ">
                <div className="p-4 pb-0 pt-0 p-lg-0 mb-4">
                  <div className="d-flex justify-content-center">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/1.png"
                      alt=""
                      className="img-fluid col-6 col-md-10"
                    />
                  </div>
                  <div className="text-center text-lg-start">
                    <h5 className="poppins-medium">Produzione di massa</h5>
                    <p className="m-0">
                      Allineamento sulla finalizzazione post-ordine della
                      produzione
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-4 pb-0 pt-0 p-lg-0 mb-4">
                  <div className="d-flex justify-content-center">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/2.png"
                      alt=""
                      className="img-fluid col-6 col-md-10"
                    />
                  </div>
                  <div className="text-center text-lg-start">
                    <h5 className="poppins-medium">
                      Processo di campionamento
                    </h5>
                    <p className="m-0">
                      Consente inoltre il controllo sulla convalida delle
                      tariffe antidumping
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-4 pb-0 pt-0 p-lg-0 mb-4">
                  <div className="d-flex justify-content-center">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/3.png"
                      alt=""
                      className="img-fluid col-6 col-md-10"
                    />
                  </div>
                  <div className="text-center text-lg-start">
                    <h5 className="poppins-medium">
                      Esplora la nostra offerta di prodotti
                    </h5>
                    <p className="m-0">
                      Corrisponderemo alle tue specifiche oltre a offrire
                      un'offerta localizzata completa (paese di origine)
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-4 pb-0 pt-0 p-lg-0 mb-4">
                  <div className="d-flex justify-content-center">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/4.png"
                      alt=""
                      className="img-fluid col-6 col-md-10"
                    />
                  </div>
                  <div className="text-center text-lg-start">
                    <h5 className="poppins-medium">
                      Organizzazione della visita in fabbrica
                    </h5>
                    <p className="m-0">
                      per fornirti approfondimenti sullo stato dell’arte dei
                      macchinari e del processo di produzione
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-4 pb-0 pt-0 p-lg-0 mb-4">
                  <div className="d-flex justify-content-center">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/5.png"
                      alt=""
                      className="img-fluid col-6 col-md-10"
                    />
                  </div>
                  <div className="text-center text-lg-start">
                    <h5 className="poppins-medium">
                      Finalizzazione del prodotto
                    </h5>
                    <p className="m-0">Post campionamento e test in batch</p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-4 pb-0 pt-0 p-lg-0 mb-4">
                  <div className="d-flex justify-content-center">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/6.png"
                      alt=""
                      className="img-fluid col-6 col-md-10"
                    />
                  </div>
                  <div className="text-center text-lg-start">
                    <h5 className="poppins-medium">
                      Supporto nel post-vendita
                    </h5>
                    <p className="m-0">
                      Insieme alla pianificazione dei ricambi: Europa e Stati
                      Uniti
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-4 pb-0 pt-0 p-lg-0 mb-4">
                  <div className="d-flex justify-content-center">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/7.png"
                      alt=""
                      className="img-fluid col-6 col-md-10"
                    />
                  </div>
                  <div className="text-center text-lg-start">
                    <h5 className="poppins-medium">
                      Estendere il supporto logistico
                    </h5>
                    <p className="m-0">
                      Per abilitare la migliore soluzione di spedizione con la
                      nostra rete estesa di partner
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-4 pb-0 pt-0 p-lg-0 mb-4">
                  <div className="d-flex justify-content-center">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/8.png"
                      alt=""
                      className="img-fluid col-6 col-md-10"
                    />
                  </div>
                  <div className="text-center text-lg-start">
                    <h5 className="poppins-medium">Invia le tue richieste</h5>
                    <p className="m-0">(immagini e specifiche della bici)</p>
                  </div>
                </div>
              </SplideSlide>
            </Splide>
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center mt-5">
          <Modal
            language={"italy"}
            className="primary-button fw-bold px-4 py-2 d-lg-block d-none"
          />
          <ModalMobile
            language={"italy"}
            className="primary-button fw-bold px-4 py-2 d-lg-none"
          />
        </div>
        {/* amigo */}
        <div className="news my-5 py-5">
          <h2 className="poppins-medium mb-5 container">
            Amigo - Un'app | Possibilità illimitate
          </h2>
          <div
            className="d-flex container pt-3"
            style={{ overflowX: "auto", gap: "2em" }}
          >
            <div className="mb-4 amigo-spec">
              <div className="position-relative">
                <img
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/amigo/fitness.png"
                  className="w-100"
                ></img>
                <div
                  className="position-absolute"
                  style={{ top: "17%", right: "14%" }}
                >
                  <div className="d-flex align-items-end">
                    <p className="fw-bold">Modalità fitness</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-4 amigo-spec">
              <div className="position-relative">
                <img
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/amigo/diagnostic.png"
                  className="w-100"
                ></img>
                <div
                  className="position-absolute"
                  style={{ top: "10%", right: "0%" }}
                >
                  <div className="d-flex align-items-end">
                    <p className="fw-bold">
                      Diagnostica<br></br>completa della bici
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-4 amigo-spec">
              <div className="position-relative">
                <img
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/amigo/sos.png"
                  className="w-100"
                ></img>
                <div
                  className="position-absolute"
                  style={{ top: "13%", right: "15%" }}
                >
                  <div className="d-flex align-items-end">
                    <p className="fw-bold">
                      Avviso SOS<br></br>di emergenza
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center mt-4 mt-lg-5">
          <Modal
            language={"italy"}
            className="primary-button fw-bold px-4 py-2 d-lg-block d-none"
          />
          <ModalMobile
            language={"italy"}
            className="primary-button fw-bold px-4 py-2 d-lg-none"
          />
        </div>
        {/* testimonials */}
        <div className="mt-5 py-lg-5 container testimonials">
          <h2 className="poppins-medium mb-3 mb-lg-5 container">
            Testimonianze
          </h2>
          <div className="mt-5 mt-lg-0">
            <Splide
              className="mb-lg-0 p-0 align-items-center"
              options={{
                arrows: true,
                start: 0,
                drag: false,
                type: "loop",
                pagination: true,
                autoplay: true,
                gap: "1.5em",
                padding: "0em",
                pauseOnHover: true,
                resetProgress: false,
                perPage: 1,
                perMove: 1,
                speed: 1000,
                interval: 4000,
                breakpoints: {
                  700: {
                    drag: true,
                    arrows: false,
                    autoplay: false,
                    perPage: 1,
                    perMove: 1,
                    gap: "1em",
                    padding: "0em",
                    pauseOnHover: false,
                    resetProgress: false,
                  },
                },
              }}
              muted={true}
            >
              <SplideSlide className="h-100">
                <div className="d-flex p-lg-4" style={{ gap: "2em" }}>
                  <div className="w-25 d-none d-lg-block">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/testimonial1.png"
                      className="rounded-0"
                    ></img>
                  </div>
                  <div className="position relative">
                    <div className="position-absolute inverted-comma d-lg-none">
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/inverted.png"
                        className="img-fluid"
                      ></img>
                    </div>
                    <div className="ps-5 ps-lg-0">
                      <h4 className="poppins-medium text-uppercase mb-4">
                        “Uno dei migliori produttori di e-bike sul mercato! Ho
                        costantemente personalizzato le e-bike secondo i miei
                        gusti e non deludono mai, consigliate 11/10!..........”
                      </h4>
                      <p className="poppins-medium mb-0">James Mill</p>
                    </div>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="d-flex p-lg-4" style={{ gap: "2em" }}>
                  <div className="w-25 d-none d-lg-block">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/testimonial2.png"
                      className="rounded-0"
                    ></img>
                  </div>
                  <div className="position relative">
                    <div className="position-absolute inverted-comma d-lg-none">
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/inverted.png"
                        className="img-fluid"
                      ></img>
                    </div>
                    <div className="ps-5 ps-lg-0">
                      <h4 className="poppins-medium text-uppercase mb-4">
                        “Il passaggio a Dynem è stato un punto di svolta per
                        noi! L'intero processo, dall'approvvigionamento alla
                        consegna, è stato fluido. Inoltre, i risparmi ottenuti
                        spostando la nostra produzione in India sono stati
                        sostanziali. Lo consiglio vivamente!"
                      </h4>
                      <p className="poppins-medium mb-0">Jake Severisson</p>
                    </div>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="d-flex p-lg-4" style={{ gap: "2em" }}>
                  <div className="w-25 d-none d-lg-block">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/testimonial3.png"
                      className="rounded-0"
                    ></img>
                  </div>
                  <div className="position relative">
                    <div className="position-absolute inverted-comma d-lg-none">
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/inverted.png"
                        className="img-fluid"
                      ></img>
                    </div>
                    <div className="ps-5 ps-lg-0">
                      <h4 className="poppins-medium text-uppercase mb-4">
                        "Lavorare con Dynem è stato a dir poco eccellente. La
                        transizione dalla Cina all'India è stata incredibilmente
                        fluida e i risparmi sui costi che abbiamo ottenuto sono
                        stati significativi. Ottimo partner per la produzione di
                        biciclette elettriche!"
                      </h4>
                      <p className="poppins-medium mb-0">Carolina Spike</p>
                    </div>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="d-flex p-lg-4" style={{ gap: "2em" }}>
                  <div className="w-25 d-none d-lg-block">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/testimonial4.png"
                      className="rounded-0"
                    ></img>
                  </div>
                  <div className="position relative">
                    <div className="position-absolute inverted-comma d-lg-none">
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/inverted.png"
                        className="img-fluid"
                      ></img>
                    </div>
                    <div className="ps-5 ps-lg-0">
                      <h4 className="poppins-medium text-uppercase mb-4">
                        "Dynem ha semplificato il nostro passaggio alla
                        produzione indiana. La loro attenzione ai dettagli e
                        l'impegno per la qualità sono stati impressionanti e i
                        vantaggi in termini di costi sono stati immediati. Siamo
                        molto soddisfatti del passaggio!”
                      </h4>
                      <p className="poppins-medium mb-0">Kevin Spearson</p>
                    </div>
                  </div>
                </div>
              </SplideSlide>
            </Splide>
          </div>
        </div>
        {/* news */}
        <div className="news">
          <div className="container mt-5 py-4 py-lg-5">
            <h2 className="poppins-medium mb-3 mb-lg-5">Nelle notizie</h2>
            <Splide
              className="mb-2 mb-lg-0 p-0 align-items-center"
              options={{
                arrows: false,
                drag: false,
                type: "slide",
                pagination: false,
                autoplay: false,
                perMove: 1,
                gap: "2em",
                padding: "0em",
                pauseOnHover: true,
                resetProgress: false,
                perPage: 4,
                speed: 1000,
                interval: 3000,
                breakpoints: {
                  700: {
                    drag: true,
                    perPage: 1.3,
                    arrows: false,
                    perMove: 1,
                    gap: "1em",
                    padding: "0em",
                    pauseOnHover: false,
                    resetProgress: false,
                    focus: 0,
                    start: 0,
                    speed: 1000,
                    interval: 3000,
                  },
                },
              }}
              muted={true}
            >
              <SplideSlide className="h-100">
                <div className="p-3" style={{ backgroundColor: "white" }}>
                  <div>
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/News+1.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/kxan.png"
                    className="img-fluid mt-2"
                  ></img>
                  <p className="my-3 news-heading poppins-medium">
                    DYNEM inizia la costruzione della più grande Gigafactory
                    integrata a ciclo elettrico al mondo...
                  </p>
                  <small>May 09, 2024</small>
                  <div
                    className="w-100 text-center py-2 mt-2"
                    style={{
                      backgroundColor: "black",
                      minHeight: "max-content",
                    }}
                  >
                    <a
                      className="text-light text-decoration-none"
                      href="https://www.kxan.com/business/press-releases/cision/20240509IO08933/dynem-begins-construction-of-worlds-biggest-integrated-electric-cycle-gigafactory-in-india/"
                    >
                      Per saperne di più
                    </a>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-3" style={{ backgroundColor: "white" }}>
                  <div>
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/News+2.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/ktla.png"
                    className="img-fluid mt-2"
                  ></img>
                  <p className="my-3 news-heading poppins-medium">
                    La DYNEM indiana creerà la più grande gigafactory integrata
                    di biciclette elettriche al mondo
                  </p>
                  <small>May 09, 2024</small>
                  <div
                    className="w-100 text-center py-2 mt-2"
                    style={{
                      backgroundColor: "black",
                      minHeight: "max-content",
                    }}
                  >
                    <a
                      className="text-light text-decoration-none"
                      href="https://fox59.com/business/press-releases/cision/20240509IO08933/dynem-begins-construction-of-worlds-biggest-integrated-electric-cycle-gigafactory-in-india/"
                    >
                      Per saperne di più
                    </a>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-3" style={{ backgroundColor: "white" }}>
                  <div>
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/News+3.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/benzinga.png"
                    className="img-fluid mt-2"
                  ></img>
                  <p className="my-3 news-heading poppins-medium">
                    DYNEM inaugura la più grande Gigafactory integrata a ciclo
                    elettrico al mondo...
                  </p>
                  <small>May 09, 2024</small>
                  <div
                    className="w-100 text-center py-2 mt-2"
                    style={{
                      backgroundColor: "black",
                      minHeight: "max-content",
                    }}
                  >
                    <a
                      className="text-light text-decoration-none"
                      href="https://www.benzinga.com/pressreleases/24/05/n38730936/dynem-begins-construction-of-worlds-biggest-integrated-electric-cycle-gigafactory-in-india"
                    >
                      Per saperne di più
                    </a>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-3" style={{ backgroundColor: "white" }}>
                  <div>
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/News+4.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/fox8.png"
                    className="img-fluid mt-2"
                  ></img>
                  <p className="my-3 news-heading poppins-medium">
                    DYNEM rafforza l’abilità manifatturiera dell’India: la più
                    grande del mondo....
                  </p>
                  <small>May 09, 2024</small>
                  <div
                    className="w-100 text-center py-2 mt-2"
                    style={{
                      backgroundColor: "black",
                      minHeight: "max-content",
                    }}
                  >
                    <a
                      className="text-light text-decoration-none"
                      href="https://www.benzinga.com/pressreleases/24/05/n38730936/dynem-begins-construction-of-worlds-biggest-integrated-electric-cycle-gigafactory-in-india"
                    >
                      Per saperne di più
                    </a>
                  </div>
                </div>
              </SplideSlide>
            </Splide>
          </div>
        </div>
        {/* FAQs */}
        <div className="container mt-5 pt-lg-5">
          <div className="d-flex faq-landing">
            <div className="faq-landing-1">
              <h2 className="poppins-medium mb-2 mb-lg-4">Domande frequenti</h2>
              <p className="mb-lg-5">
                Hai domande? Abbiamo già le risposte. È come se potessimo
                sentirti pensare.
              </p>
              <div className="d-lg-block d-none">
                <h4 className="poppins-medium mb-2">
                  Ci siamo persi qualcosa?
                </h4>
                <p className="mb-lg-5">
                  Partecipa a una chiamata individuale con i nostri esperti.
                </p>
                <div className="req-call my-5 my-lg-0">
                  <a
                    href="tel:+918956703515"
                    className="btn btn-dark px-5 d-flex justify-content-between rounded-0"
                    style={{ width: "fit-content" }}
                  >
                    Richiedi una chiamata
                  </a>
                </div>
              </div>
            </div>
            <div className="accordion faq-landing-2" id="accordionExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    Cosa rende DYNEM diverso dagli altri produttori di e-bike?
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body poppins-thin">
                    <p>
                      DYNEM si distingue per l'innovazione all'avanguardia, le
                      pratiche ecocompatibili e le ampie opzioni di
                      personalizzazione. La nostra gigafactory all'avanguardia,
                      la conformità globale e il team di esperti garantiscono
                      qualità di prim'ordine e supporto continuo dalla
                      produzione al post-vendita, lasciandoti solo la massima
                      tranquillità.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Posso personalizzare la mia e-bike con DYNEM?
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                      Assolutamente! DYNEM offre ampie opzioni di
                      personalizzazione, permettendoti di adattare la tua e-bike
                      al tuo stile e alle tue esigenze specifiche. Il nostro
                      servizio OEM ti consente di personalizzare il marchio, le
                      specifiche e le funzionalità in base alle tue preferenze.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Quante e-bike produce DYNEM ogni anno?
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                      DDYNEM ha la capacità di produrre e personalizzare 500.000
                      e-bike all'anno. I nostri efficienti processi produttivi e
                      le nostre infrastrutture di livello mondiale ci consentono
                      di soddisfare la crescente domanda globale.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingFour">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    Quali sono i vantaggi delle soluzioni antidumping di DYNEM?
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                      Le soluzioni legali antidumping di DYNEM consentono agli
                      importatori di risparmiare fino all'87% sulle tasse. I
                      nostri prodotti sono conformi alle normative globali,
                      garantendo condizioni di parità senza tasse antidumping.
                      Ciò rende DYNEM una scelta conveniente e conforme per gli
                      importatori di tutto il mondo.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingfive">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    How does DYNEM support sustainability?
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFive"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                      DYNEM è impegnata nella sostenibilità attraverso pratiche
                      ecocompatibili e tecnologie innovative. Le nostre e-bike
                      sono progettate per ridurre le emissioni di carbonio e
                      promuovere un futuro più verde, in linea con la nostra
                      visione di mobilità sostenibile.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingSix">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    Che tipo di supporto post-vendita fornisce DYNEM?
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingSix"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                      DYNEM dà priorità alla soddisfazione del cliente con un
                      supporto post-vendita dedicato. Dall'acquisto iniziale
                      alla manutenzione a lungo termine, il nostro team si
                      impegna a fornire un'assistenza continua e affidabile,
                      garantendo un'esperienza fluida e soddisfacente per i
                      nostri clienti.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-lg-none text-center mt-3">
              <h4 className="poppins-medium mb-2">Ci siamo persi qualcosa?</h4>
              <p className="mb-lg-5">
                Partecipa a una chiamata individuale con i nostri esperti.
              </p>
              <div className="req-call my-4">
                <a
                  href="tel:+918956703515"
                  className="btn btn-dark px-5 d-flex justify-content-between rounded-0"
                  style={{ width: "fit-content" }}
                >
                  Richiedi una chiamata
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="d-lg-block d-none">
          <div className="d-flex justify-content-center align-items-center mt-4 mt-lg-5">
            <Modal
              language={"italy"}
              className="primary-button fw-bold px-4 py-2"
            />
          </div>
        </div>

        {/* contact us */}
        <div className="mt-5 py-lg-5" style={{ backgroundColor: "black" }}>
          <div className="container py-lg-5 py-4">
            <div className="d-flex mb-3 mb-lg-0" style={{ gap: "4em" }}>
              <div className="d-none d-lg-block w-50">
                <div className="row">
                  <div className="col-6 p-0">
                    <div className="d-flex justify-content-end">
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/grid1.png"
                        className="w-75 p-3"
                      ></img>
                    </div>
                    <div className="d-flex justify-content-end">
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/grid5.png"
                        className="w-100 p-3"
                      ></img>
                    </div>
                  </div>
                  <div className="col-6 p-0">
                    <div className="d-flex justify-content-start mt-5">
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/grid2.png"
                        className="w-100 p-3"
                      ></img>
                    </div>
                    <div className="d-flex justify-content-start">
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/grid4.png"
                        className="w-75 p-3"
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-none d-lg-block">
                <h2 className="poppins-medium mb-4 text-light">
                  Connettiamoci
                </h2>
                <form className="contact-us-landing" onSubmit={submit}>
                  <div class="mb-3 d-flex" style={{ gap: "1em" }}>
                    <div className="w-50">
                      <label
                        for="exampleInputEmail1"
                        className="form-label text-light"
                      >
                        Nome e cognome*
                      </label>
                      <input
                        type="text"
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                        required
                        class="form-control rounded-0"
                        aria-describedby="emailHelp"
                      />
                    </div>
                    <div className="w-50">
                      <label
                        for="exampleInputEmail1"
                        className="form-label text-light"
                      >
                        Paese*
                      </label>
                      <input
                        type="text"
                        value={country}
                        onChange={(e) => {
                          setCountry(e.target.value);
                        }}
                        required
                        class="form-control rounded-0"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  </div>
                  <div class="mb-3 d-flex" style={{ gap: "1em" }}>
                    <div class="w-50">
                      <label
                        for="exampleInputPassword1"
                        class="form-label text-light"
                      >
                        Contatta il n*
                      </label>
                      <PhoneInput
                        value={phoneNumber}
                        onChange={setPhoneNumber}
                        required
                        defaultCountry="IN"
                        className="form-control w-100 rounded-0"
                      />
                    </div>
                    <div className="w-50">
                      <label
                        for="exampleInputEmail1"
                        className="form-label text-light"
                      >
                        E-mail di lavoro*
                      </label>
                      <input
                        type="email"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        required
                        class="form-control rounded-0"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  </div>

                  <div class="mb-4 d-flex" style={{ gap: "1em" }}>
                    <div className="w-100">
                      <label
                        for="exampleInputEmail1"
                        className="form-label text-light"
                      >
                        Organizzazione*
                      </label>
                      <input
                        type="text"
                        value={companyName}
                        required
                        onChange={(e) => {
                          setCompanyName(e.target.value);
                        }}
                        class="form-control rounded-0"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label
                      for="exampleInputEmail1"
                      className="form-label text-light"
                    >
                      Pubblica la tua richiesta*
                    </label>
                    <textarea
                      class="form-control rounded-0"
                      required
                      id="exampleFormControlTextarea1"
                      rows="3"
                      value={enquiry}
                      onChange={(e) => {
                        setEnquiry(e.target.value);
                      }}
                    ></textarea>
                  </div>
                  <button
                    type="submit"
                    class="btn btn-light w-100 poppins-medium rounded-0"
                  >
                    Ottieni un preventivo
                  </button>
                </form>
              </div>
            </div>
            <div className="d-lg-none mb-5">
              <h2 className="poppins-medium mb-4 text-light">Connettiamoci</h2>
              <form className="contact-us-landing" onSubmit={submit}>
                <div class="mb-3 d-flex" style={{ gap: "1em" }}>
                  <div className="w-50">
                    <label
                      for="exampleInputEmail1"
                      className="form-label text-light"
                    >
                      Nome e cognome*
                    </label>
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      required
                      class="form-control rounded-0"
                      aria-describedby="emailHelp"
                    />
                  </div>
                  <div className="w-50">
                    <label
                      for="exampleInputEmail1"
                      className="form-label text-light"
                    >
                      Paese*
                    </label>
                    <input
                      type="text"
                      value={country}
                      onChange={(e) => {
                        setCountry(e.target.value);
                      }}
                      required
                      class="form-control rounded-0"
                      aria-describedby="emailHelp"
                    />
                  </div>
                </div>
                <div class="mb-3 d-flex" style={{ gap: "1em" }}>
                  <div class="w-50">
                    <label
                      for="exampleInputPassword1"
                      class="form-label text-light"
                    >
                      Contatta il n*
                    </label>
                    <PhoneInput
                      value={phoneNumber}
                      onChange={setPhoneNumber}
                      required
                      defaultCountry="IN"
                      className="form-control w-100 rounded-0"
                    />
                  </div>
                  <div className="w-50">
                    <label
                      for="exampleInputEmail1"
                      className="form-label text-light"
                    >
                      E-mail di lavoro*
                    </label>
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      required
                      class="form-control rounded-0"
                      aria-describedby="emailHelp"
                    />
                  </div>
                </div>

                <div class="mb-4 d-flex" style={{ gap: "1em" }}>
                  <div className="w-100">
                    <label
                      for="exampleInputEmail1"
                      className="form-label text-light"
                    >
                      Organizzazione*
                    </label>
                    <input
                      type="text"
                      value={companyName}
                      required
                      onChange={(e) => {
                        setCompanyName(e.target.value);
                      }}
                      class="form-control rounded-0"
                      aria-describedby="emailHelp"
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <label
                    for="exampleInputEmail1"
                    className="form-label text-light"
                  >
                    Pubblica la tua richiesta*
                  </label>
                  <textarea
                    class="form-control rounded-0"
                    id="exampleFormControlTextarea1"
                    required
                    rows="3"
                    value={enquiry}
                    onChange={(e) => {
                      setEnquiry(e.target.value);
                    }}
                  ></textarea>
                </div>
                <button
                  type="submit"
                  class="btn btn-light w-100 poppins-medium rounded-0"
                >
                  Ottieni un preventivo
                </button>
              </form>
            </div>
            <div className="row p-2 d-lg-none">
              <div className="col-6 p-0">
                <div className="d-flex justify-content-end">
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/grid1.png"
                    className="w-75 p-2"
                  ></img>
                </div>
                <div className="d-flex justify-content-end">
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/grid5.png"
                    className="w-100 p-2"
                  ></img>
                </div>
              </div>
              <div className="col-6 p-0">
                <div className="d-flex justify-content-start mt-5">
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/grid2.png"
                    className="w-100 p-2"
                  ></img>
                </div>
                <div className="d-flex justify-content-start">
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/grid4.png"
                    className="w-75 p-2"
                  ></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default GetQuoteItaly;
