import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import "../css/landingPage.css";
import cst from "../images/partnerLogos/cst.png";
import kenli from "../images/partnerLogos/kenli.png";
import kmc from "../images/partnerLogos/kmc.png";
import maxxis from "../images/partnerLogos/maxxis.png";
import wtb from "../images/partnerLogos/wtb.png";
import schwalbe from "../images/partnerLogos/schwalbe.png";
import neco from "../images/partnerLogos/neco.png";
import ralson from "../images/partnerLogos/ralson.png";
import prowheel from "../images/partnerLogos/prowheel.png";
import sunrace from "../images/partnerLogos/sunrace.png";
import shimano from "../images/partnerLogos/shimano.png";
import sram from "../images/partnerLogos/sram.png";
import rockshox from "../images/partnerLogos/rockshox.png";
import microshift from "../images/partnerLogos/microshift.png";
import axios from "axios";
import PhoneInput from "react-phone-number-input";
import Meta from "../components/Meta";
import Modal from "../components/Modal";
import ModalMobile from "../components/ModalMobile";
import ReactPlayer from "react-player";
import CustomBike from "../components/CustomBike";
import "../css/navbar.css";
import logo from "../images/brandLogos/Dynem red white.png";
import { Icon } from "@iconify/react";

const GetQuoteDutch = () => {
  const featuresData = [
    {
      key: "precisionControllers",
      heading: "Precisiecontrollers",
      description:
        "Controllers in een e-bike maken gebruik van sensoren en algoritmen om de vermogensafgifte naadloos aan te passen. Het intelligente systeem verbetert de prestaties en maakt de e-bike intuïtiever en gebruiksvriendelijker.",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/Controlerrrr.png",
    },
    {
      key: "highPerformanceChargers",
      heading: "Hoogwaardige opladers",
      description:
        "De laders worden geleverd in een reeks aanpasbare opties die tegemoetkomen aan uw voorkeuren, gebaseerd op wereldwijde compatibiliteit, draagbaarheid of uitgebreide energieflexibiliteit.",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/Chargerrrr.png",
    },
    {
      key: "multifunctionalDisplays",
      heading: "Multifunctionele beeldschermen",
      description:
        "Deze LCD-schermen bieden realtime gegevens zoals snelheid en batterij, waardoor rijders hun rijmodi kunnen controleren en aanpassen.",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/Displayyyyy.png",
    },
    {
      key: "longLastingBatteries",
      heading: "Batterijen die lang meegaan",
      description:
        "Dit elektrische systeem slaat energie op en drijft de elektromotor aan. Deze technologie is ontworpen om consistente prestaties, een langere levensduur van de batterij en een optimale vermogensafgifte te garanderen, waardoor de algehele rijervaring wordt verbeterd.",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/Baterryyyyy.png",
    },
    {
      key: "efficientMotors",
      heading: "Efficiënte motoren",
      description:
        "Onze veelzijdige en innovatieve motoren zijn ontworpen om betrouwbare en nauwkeurige prestaties te leveren, waardoor optimale bruikbaarheid in verschillende toepassingen wordt gegarandeerd.",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/motorrrr.png",
    },
  ];
  const [activeFeature1, setActiveFeature1] = useState("precisionControllers");
  const handleFeature1Click = (feature) => {
    setActiveFeature1(feature.key);
  };
  const getActiveFeature1 = () => {
    return featuresData.find((feature) => feature.key === activeFeature1);
  };
  const activeFeatureObj1 = getActiveFeature1();
  const [open, openNav] = useState(false);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  }, []);

  const closeNavforDeadLink = () => {
    openNav(false);
  };

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [country, setCountry] = useState("");
  const [enquiry, setEnquiry] = useState("");

  const componentData = [
    {
      key: "Frame",
    },
    {
      key: "Motor",
    },
    {
      key: "Derailleur",
    },
    {
      key: "Battery",
    },
    {
      key: "Display",
    },
  ];
  const [activeComponent, setActiveComponent] = useState("Frame");
  const handleComponentClick = (feature) => {
    setActiveComponent(feature.key);
  };

  const navigate = useNavigate();

  const submit = async (e) => {
    e.preventDefault();
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const reqBody = {
      name,
      email,
      phoneNumber,
      companyName,
      country,
      enquiry,
    };
    const { data } = await axios.post(
      "/api/form/request-a-quote",
      reqBody,
      config
    );
    if (data) {
      navigate("/details-submitted");
    }
  };
  return (
    <>
      {/* landing page main banner */}
      <Meta
        title={"Get a Quote - Get Wholesale E-bike Purchase Quote from Dynem"}
        description={
          "Get a wholesale e-bike purchase quote from Dynem, a leading e-bike manufacturer and supplier. Request your personalized quote today for bulk orders."
        }
        cannonicalTag={"https://www.dynem.in/get-quote/nl"}
      />
      {/* desktop navbar */}
      <div
        className="position-sticky w-100 navbar"
        style={{ zIndex: "99", top: "0%" }}
      >
        <div className="row container mx-auto px-3 px-lg-0">
          <div className="col-12 col-md-4 d-flex justify-content-start p-0">
            <Link to={"/"}>
              <img src={logo} alt="dynem-logo" className="logo"></img>
            </Link>
          </div>
          <div className="col-5 d-flex nav-items p-0"></div>
          <div className="col-3 p-0 d-none d-lg-block d-md-block">
            <div className="d-flex justify-content-end align-items-center">
              <Modal
                language={"dutch"}
                className="primary-button fw-bold px-4 py-2"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="get-quote-landing">
        {/* hero-banner */}
        <div className="hero-banner position-relative">
          <picture className="main-banner-img">
            <source
              media="(max-width:650px)"
              srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/banners/heroBanner_mobile.jpg"
            />
            <source
              media="(min-width:651px)"
              srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/banners/heroBanner.jpg"
            />
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/banners/heroBanner.jpg"
              alt="hero-banner"
              className="d-block w-100"
            />
          </picture>
          <div
            className="d-none d-lg-block position-absolute"
            style={{ top: "10%", left: "7%" }}
          >
            <h1
              className="poppins-bold text-light"
              style={{ fontSize: "3.5em" }}
            >
              <span style={{ color: "#ff0000" }}>STIMULEER UW</span>
              <br></br>
              E-BIKE-PRODUCTIE
            </h1>
            <p className="text-light w-50">
              Verbeter uw e-bikeproductie met de precisietechniek en het
              deskundige vakmanschap van Dynem, en lever superieure resultaten
              waarmee uw merk zich onderscheidt.
            </p>
          </div>
          <div className="d-lg-none position-absolute" style={{ top: "0%" }}>
            <div className="d-flex justify-content-center w-100 p-4">
              <h1
                className="poppins-bold text-light"
                style={{ fontSize: "2.5em" }}
              >
                <span style={{ color: "#ff0000" }}>STIMULEER UW</span>
                <br></br>
                E-BIKE-PRODUCTIE
              </h1>
            </div>
          </div>
          <div
            className="d-lg-block d-none position-absolute"
            style={{
              bottom: "10%",
              left: "7%",
              backgroundColor: "rgba(151, 151, 151, 0.56)",
            }}
          >
            <div
              className="d-flex p-3 px-5 text-center poppins-medium"
              style={{
                gap: "2em",
              }}
            >
              <h5 className="text-light">
                5,00,000+<br></br>Capaciteit van e-bikes
              </h5>
              <h5 className="text-light">
                4,00,000+<br></br>Componentcapaciteit
              </h5>
              <h5 className="text-light">
                Interne<br></br>aandrijflijn
              </h5>
              <Modal
                language={"dutch"}
                className="primary-button fw-bold px-4 py-2"
              />
            </div>
          </div>
          <div
            className="d-lg-none position-absolute w-100"
            style={{
              bottom: "10%",
              backgroundColor: "rgba(151, 151, 151, 0.56)",
            }}
          >
            <div
              className="d-flex p-3 text-center poppins-medium"
              style={{
                gap: "1.7em",
                fontSize: "0.8em",
              }}
            >
              <h5 className="text-light">
                5,00,000+<br></br>Capaciteit van e-bikes
              </h5>
              <h5 className="text-light">
                4,00,000+<br></br>Componentcapaciteit
              </h5>
              <h5 className="text-light">
                Interne<br></br>aandrijflijn
              </h5>
            </div>
          </div>
          <div className="d-lg-none get-quote-banner-button-mb position-absolute">
            <div className="d-flex" style={{ gap: "1.5em" }}>
              <ModalMobile
                language={"dutch"}
                className="primary-button fw-bold px-4 py-2"
              />
            </div>
          </div>
        </div>
        {/* world class products */}
        <div className="">
          <div className=" mt-5 py-4 py-lg-5">
            <h2 className="poppins-medium mb-3 mb-lg-5 text-center container">
              Klasseproductie, uit India, voor de wereld!
            </h2>
            <div className="news py-5">
              <Splide
                className="mb-2 mb-lg-0 p-0 align-items-center container"
                options={{
                  arrows: false,
                  drag: false,
                  type: "slide",
                  pagination: false,
                  autoplay: false,
                  perMove: 1,
                  gap: "2em",
                  padding: "0em",
                  pauseOnHover: true,
                  resetProgress: false,
                  perPage: 4,
                  speed: 1000,
                  interval: 3000,
                  breakpoints: {
                    700: {
                      drag: true,
                      perPage: 1.3,
                      arrows: false,
                      perMove: 1,
                      gap: "1em",
                      padding: "0em",
                      pauseOnHover: false,
                      resetProgress: false,
                      focus: 0,
                      start: 0,
                      speed: 1000,
                      interval: 3000,
                    },
                  },
                }}
                muted={true}
              >
                <SplideSlide className="h-100">
                  <div className="p-3" style={{ backgroundColor: "white" }}>
                    <div>
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/products/ecycle.jpg"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <h5 className="poppins-medium mt-3 text-center">
                      E-fietsen
                    </h5>
                  </div>
                </SplideSlide>
                <SplideSlide className="h-100">
                  <div className="p-3" style={{ backgroundColor: "white" }}>
                    <div>
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/products/motor.jpg"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <h5 className="poppins-medium mt-3 text-center">Motor</h5>
                  </div>
                </SplideSlide>
                <SplideSlide className="h-100">
                  <div className="p-3" style={{ backgroundColor: "white" }}>
                    <div>
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/products/battery.jpg"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <h5 className="poppins-medium mt-3 text-center">
                      Batterij
                    </h5>
                  </div>
                </SplideSlide>
                <SplideSlide className="h-100">
                  <div className="p-3" style={{ backgroundColor: "white" }}>
                    <div>
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/products/dispaly.jpg"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <h5 className="poppins-medium mt-3 text-center">
                      Weergave
                    </h5>
                  </div>
                </SplideSlide>
              </Splide>
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center py-4">
            <Modal
              language={"dutch"}
              className="primary-button fw-bold px-4 py-2 d-lg-block d-none"
            />
            <ModalMobile
              language={"dutch"}
              className="primary-button fw-bold px-4 py-2 d-lg-none"
            />
          </div>
        </div>
        {/* company logos */}
        <div className="container my-5 py-lg-5 d-none d-lg-block">
          <Splide
            className="flex align-items-center"
            extensions={{ AutoScroll }}
            options={{
              type: "loop",
              autoScroll: {
                speed: 1,
                pauseOnHover: false,
              },
              perPage: 6,
              drag: "free",
              arrows: false,
              pagination: false,
              pauseOnHover: false,
              gap: "3em",
              padding: "1em",
              breakpoints: {
                640: {
                  perPage: 3,
                  gap: "1em",
                },
              },
            }}
          >
            <SplideSlide className="h-100">
              <div>
                <img src={shimano} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={kmc} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={maxxis} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={wtb} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={rockshox} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={neco} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={ralson} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={sunrace} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={microshift} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={sram} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={schwalbe} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
          </Splide>
        </div>
        <div className="my-5 py-lg-5 d-lg-none">
          <Splide
            className="flex align-items-center"
            extensions={{ AutoScroll }}
            options={{
              type: "loop",
              autoScroll: {
                speed: 1,
                pauseOnHover: false,
              },
              perPage: 6,
              drag: "free",
              arrows: false,
              pagination: false,
              pauseOnHover: false,
              gap: "3em",
              padding: "1em",
              breakpoints: {
                640: {
                  perPage: 3,
                  gap: "1em",
                },
              },
            }}
          >
            <SplideSlide className="h-100">
              <div>
                <img src={shimano} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={kmc} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={maxxis} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={wtb} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={rockshox} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={neco} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={ralson} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={sunrace} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={microshift} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={sram} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={schwalbe} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
          </Splide>
        </div>
        {/* why choose us */}
        <div className="news">
          <div className="container mt-5 pt-lg-5 pt-5">
            <h2 className="poppins-medium mb-2">Uw partner in succes</h2>
            <h4 className="">Dit is waarom wij opvallen</h4>
            <div className="py-5">
              <div className="d-flex flex-wrap justify-content-between text-center w-100 mb-3 mb-lg-5 why-choose-us">
                <div className="flex-item">
                  <div className="d-flex justify-content-center mb-3">
                    <img
                      className="img-fluid"
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/production.png"
                    ></img>
                  </div>

                  <p className="m-0 poppins-medium">
                    500,000<br></br>Productiecapaciteit
                  </p>
                </div>
                <div className="flex-item">
                  <div className="d-flex justify-content-center mb-3">
                    <img
                      className="img-fluid"
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/component.png"
                    ></img>
                  </div>
                  <p className="m-0 poppins-medium">
                    400,000&nbsp;Capaciteit van componenten
                  </p>
                </div>
                <div className="flex-item">
                  <div className="d-flex justify-content-center mb-3">
                    <img
                      className="img-fluid"
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/cost.png"
                    ></img>
                  </div>
                  <p className="m-0 poppins-medium">
                    87%<br></br>Kostenvoordelen
                  </p>
                </div>
                <div className="flex-item">
                  <div className="d-flex justify-content-center mb-3">
                    <img
                      className="img-fluid"
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/iot.png"
                    ></img>
                  </div>
                  <p className="m-0 poppins-medium">
                    IOT ingeschakeld<br></br>Oplossingen
                  </p>
                </div>
              </div>
              <div className="d-flex flex-wrap flex-wrap-2 justify-content-between text-center w-100 why-choose-us">
                <div className="flex-item">
                  <div className="d-flex justify-content-center mb-3">
                    <img
                      className="img-fluid"
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/certificate.png"
                    ></img>
                  </div>

                  <p className="mt-2 poppins-medium">
                    Europa<br></br>Certificeringen
                  </p>
                </div>
                <div className="flex-item">
                  <div className="d-flex justify-content-center mb-3">
                    <img
                      className="img-fluid"
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/in-stock.png"
                    ></img>
                  </div>
                  <p className="mt-2 poppins-medium">
                    Klaar voorraad<br></br>beschikbaar
                  </p>
                </div>
                <div className="flex-item">
                  <div className="d-flex justify-content-center mb-3">
                    <img
                      className="img-fluid"
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/automation.png"
                    ></img>
                  </div>
                  <p className="mt-2 poppins-medium">
                    Alle componenten<br></br>productie in eigen huis
                  </p>
                </div>
                <div className="flex-item">
                  <div className="d-flex justify-content-center mb-3">
                    <img
                      className="img-fluid"
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/container.png"
                    ></img>
                  </div>
                  <p className="mt-2 poppins-medium">
                    Juridische antidumping<br></br>Oplossing
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* range of dts */}
        <div className="container mt-5 pt-4 pt-lg-5">
          <h2 className="poppins-medium mb-4 mb-lg-5">
            Componenten die elke kilometer kracht geven
          </h2>
          <div className="d-lg-none pt-4">
            <Splide
              className="mb-5 p-0 align-items-center"
              options={{
                drag: true,
                perPage: 1.2,
                arrows: false,
                pagination: false,
                autoplay: false,
                perMove: 1,
                interval: 4000,
                gap: "1em",
                padding: "0em",
                pauseOnHover: false,
                resetProgress: false,
                focus: 0,
                start: 0,
                speed: 500,
              }}
            >
              {featuresData.slice(0, 4).map((feature) => (
                <SplideSlide className="feature-btn-width">
                  <button
                    key={feature}
                    className={
                      feature.key === activeFeature1
                        ? "em-btn-1 rounded-0"
                        : "em-btn-2 rounded-0"
                    }
                    onClick={() => handleFeature1Click(feature)}
                  >
                    {feature.heading}
                  </button>
                </SplideSlide>
              ))}
            </Splide>
          </div>
          <div className="mb-5 d-none d-lg-block">
            <div
              className="d-flex justify-content-center mb-3"
              style={{ gap: "1em" }}
            >
              {featuresData.map((feature) => (
                <button
                  key={feature}
                  className={
                    feature.key === activeFeature1
                      ? "em-btn-1 rounded-0"
                      : "em-btn-2 rounded-0"
                  }
                  onClick={() => handleFeature1Click(feature)}
                >
                  {feature.heading}
                </button>
              ))}
            </div>
          </div>
          <div className="d-none d-lg-block my-5 px-5">
            <div className="d-flex align-items-center justify-content-center">
              <div className="feature-text w-100">
                <h1>{activeFeatureObj1.heading}</h1>{" "}
                <p>{activeFeatureObj1.description}</p>
              </div>
              <div className="w-100">
                <img
                  src={activeFeatureObj1.image}
                  alt="amigo-feature"
                  className="img-fluid"
                ></img>
              </div>
            </div>
          </div>
          <div className="row mt-5 d-lg-none">
            <div className="col-12 col-lg-6 feature-text">
              <h1>{activeFeatureObj1.heading}</h1>{" "}
              <p>{activeFeatureObj1.description}</p>
            </div>
            <div className="col-12 col-lg-6">
              <div className="d-flex d-lg-block justify-content-center">
                <img
                  src={activeFeatureObj1.image}
                  alt="amigo-feature"
                  className="img-fluid mx-auto"
                ></img>
              </div>
            </div>
          </div>
        </div>
        {/* range of e-bikes */}
        <div className="mt-5 pt-lg-5 ">
          <div className="position-relative">
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/banners/breaker2.jpg"
              className="img-fluid"
            ></img>
            <div
              className="position-absolute d-lg-block d-none"
              style={{ top: "25%", left: "58%" }}
            >
              <div>
                <h1
                  className="poppins-medium text-light"
                  style={{ fontSize: "5em" }}
                >
                  Ontdek onze<br></br>elektrische vloott
                </h1>
              </div>
            </div>
          </div>
          <h2 className="poppins-medium d-lg-none my-4 text-center">
            Ontdek onze elektrische vloot
          </h2>
          <div className=" py-3 d-lg-none">
            <Splide
              className="mb-2 mb-lg-0 p-0 align-items-center container"
              options={{
                arrows: false,
                drag: false,
                type: "slide",
                pagination: false,
                autoplay: false,
                perMove: 1,
                gap: "2em",
                padding: "0em",
                pauseOnHover: true,
                resetProgress: false,
                perPage: 4,
                speed: 1000,
                interval: 3000,
                breakpoints: {
                  700: {
                    drag: true,
                    perPage: 1.3,
                    arrows: false,
                    perMove: 1,
                    gap: "1em",
                    padding: "0em",
                    pauseOnHover: false,
                    resetProgress: false,
                    focus: 0,
                    start: 0,
                    speed: 1000,
                    interval: 3000,
                  },
                },
              }}
              muted={true}
            >
              <SplideSlide className="h-100">
                <div className="p-3 news">
                  <div>
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/ebikes/mountain.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="mt-3 text-center" style={{ height: "40px" }}>
                    E-bikes voor in de bergen
                  </h4>
                  <div style={{ height: "160px" }}>
                    <p className="text-center m-0">
                      We zijn niet alleen een fabrikant van e-bikes in de
                      bergen; wij zijn pioniers in offroad-avontuur. Ontdek een
                      reeks e-bikes die zorgvuldig zijn ontworpen voor ruig
                      terrein en spannende ritten.
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-3 news">
                  <div>
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/ebikes/fat-tyre.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="mt-3 text-center" style={{ height: "40px" }}>
                    E-bikes met opvouwbare en dikke banden
                  </h4>
                  <div style={{ height: "160px" }}>
                    <p className="text-center m-0">
                      Als toonaangevende fabrikant van opvouwbare e-bikes biedt
                      DYNEM u de ultieme mix van gemak en stijl. Onze opvouwbare
                      e-bikes zijn perfect voor stadsverkenners en jetsetters.
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-3 news">
                  <div>
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/ebikes/city.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="mt-3 text-center" style={{ height: "40px" }}>
                    Elektrische stadsfietsen
                  </h4>
                  <div style={{ height: "160px" }}>
                    <p className="text-center m-0">
                      Als vooraanstaande fabrikant van stadsfietsen
                      herdefinieert DYNEM het woon-werkverkeer in de stad met
                      strakke, efficiënte en stijlvolle ritten. Onze
                      stadsfietsen combineren geavanceerde technologie met een
                      modern design, zodat u gemakkelijk door het verkeer
                      glijdt.
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-3 news">
                  <div>
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/ebikes/cargo.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="mt-3 text-center" style={{ height: "40px" }}>
                    Levering e-bikes
                  </h4>
                  <div style={{ height: "160px" }}>
                    <p className="text-center m-0">
                      Als toonaangevende fabrikant van bakfietsen levert DYNEM
                      topprestaties en betrouwbaarheid voor elke bezorging. Onze
                      e-bikes zijn ontworpen voor de eisen van stedelijke
                      logistiek, krachtige motoren en voldoende laadruimte.
                    </p>
                  </div>
                </div>
              </SplideSlide>
            </Splide>
          </div>
          <div className="range-ebikes container d-lg-block d-none py-5 my-5">
            <Splide
              className="mb-2 mb-lg-0 p-0 align-items-center text-center"
              options={{
                arrows: true,
                start: 0,
                drag: false,
                type: "loop",
                pagination: true,
                autoplay: true,
                gap: "3em",
                padding: "1em",
                pauseOnHover: true,
                resetProgress: false,
                perPage: 1,
                speed: 1000,
                interval: 4000,
                breakpoints: {
                  700: {
                    drag: true,
                    autoplay: false,
                    pagination: "false",
                    perPage: 1,
                    perMove: 1,
                    gap: "2em",
                    padding: "0em",
                    arrows: false,
                    pauseOnHover: false,
                    resetProgress: false,
                  },
                },
              }}
              muted={true}
            >
              <SplideSlide className="h-100">
                <div className="p-4 pt-0 p-lg-0 d-flex align-items-center ecycle-range-card">
                  <div className="">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/ebikes/mountain.png"
                      alt=""
                      className="w-100"
                    />
                  </div>
                  <div className="text-start p-3">
                    <h4 className="poppins-medium">
                      E-bikes voor in de bergen
                    </h4>
                    <p className="">
                      We zijn niet alleen een fabrikant van e-bikes in de
                      bergen; wij zijn pioniers in offroad-avontuur. Ontdek een
                      reeks e-bikes die zorgvuldig zijn ontworpen voor ruig
                      terrein en spannende ritten.
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-4 pt-0 p-lg-0 d-flex align-items-center ecycle-range-card">
                  <div className="">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/ebikes/fat-tyre.png"
                      alt=""
                      className="w-100"
                    />
                  </div>
                  <div className="text-start p-3">
                    <h4 className="poppins-medium">
                      E-bikes met opvouwbare en dikke banden
                    </h4>
                    <p className="">
                      Als toonaangevende fabrikant van opvouwbare e-bikes biedt
                      DYNEM u de ultieme mix van gemak en stijl. Onze opvouwbare
                      e-bikes zijn perfect voor stadsverkenners en jetsetters.
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-4 pt-0 p-lg-0 d-flex align-items-center ecycle-range-card">
                  <div className="">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/ebikes/city.png"
                      alt=""
                      className="w-100"
                    />
                  </div>
                  <div className="text-start p-3">
                    <h4 className="poppins-medium">Elektrische stadsfietsen</h4>
                    <p className="">
                      Als vooraanstaande fabrikant van stadsfietsen
                      herdefinieert DYNEM het woon-werkverkeer in de stad met
                      strakke, efficiënte en stijlvolle ritten. Onze
                      stadsfietsen combineren geavanceerde technologie met een
                      modern design, zodat u gemakkelijk door het verkeer
                      glijdt.
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-4 pt-0 p-lg-0 d-flex align-items-center ecycle-range-card">
                  <div className="">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/ebikes/cargo.png"
                      alt=""
                      className="w-100"
                    />
                  </div>
                  <div className="text-start p-3">
                    <h4 className="poppins-medium">Levering e-bikes</h4>
                    <p className="">
                      Als toonaangevende fabrikant van bakfietsen levert DYNEM
                      topprestaties en betrouwbaarheid voor elke bezorging. Onze
                      e-bikes zijn ontworpen voor de eisen van stedelijke
                      logistiek, krachtige motoren en voldoende laadruimte.
                    </p>
                  </div>
                </div>
              </SplideSlide>
            </Splide>
          </div>
        </div>
        {/* certifications */}
        <div className="news mt-5 pt-lg-5 pt-4 mb-5">
          <h2 className="poppins-medium mb-3 mb-lg-4 container">
            Certificeringen
          </h2>
          <div className="container pb-4">
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/certifications__png.png"
              alt="certifications"
              className="w-100"
            ></img>
          </div>
        </div>
        <div className="container mt-5 pt-lg-5">
          <h2 className="poppins-medium mb-2">Maak de overstap</h2>
          <h4 className="mb-3 mb-lg-5">Vereenvoudig uw productie met ons</h4>
          <div className="mx-lg-5 switching">
            <Splide
              className="mb-lg-0 p-0 align-items-center"
              options={{
                arrows: true,
                start: 0,
                drag: false,
                type: "loop",
                pagination: true,
                autoplay: true,
                gap: "3em",
                padding: "0em",
                pauseOnHover: true,
                resetProgress: false,
                perPage: 4,
                perMove: 1,
                speed: 1000,
                interval: 4000,
                breakpoints: {
                  700: {
                    drag: true,
                    arrows: false,
                    autoplay: false,
                    perPage: 1,
                    perMove: 1,
                    gap: "0em",
                    padding: "0em",
                    pauseOnHover: false,
                    resetProgress: false,
                  },
                },
              }}
              muted={true}
            >
              <SplideSlide className="h-100 ">
                <div className="p-4 pb-0 pt-0 p-lg-0 mb-4">
                  <div className="d-flex justify-content-center">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/1.png"
                      alt=""
                      className="img-fluid col-6 col-md-10"
                    />
                  </div>
                  <div className="text-center text-lg-start">
                    <h5 className="poppins-medium">Massaproductie</h5>
                    <p className="m-0">
                      Afstemming op de voltooiing van de postorderproductie
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-4 pb-0 pt-0 p-lg-0 mb-4">
                  <div className="d-flex justify-content-center">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/2.png"
                      alt=""
                      className="img-fluid col-6 col-md-10"
                    />
                  </div>
                  <div className="text-center text-lg-start">
                    <h5 className="poppins-medium">Bemonsteringsproces</h5>
                    <p className="m-0">
                      Maakt ook controle op anti-dumpingtariefvalidatie mogelijk
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-4 pb-0 pt-0 p-lg-0 mb-4">
                  <div className="d-flex justify-content-center">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/3.png"
                      alt=""
                      className="img-fluid col-6 col-md-10"
                    />
                  </div>
                  <div className="text-center text-lg-start">
                    <h5 className="poppins-medium">
                      Ontdek ons ​​productaanbod
                    </h5>
                    <p className="m-0">
                      We matchen uw specificaties en bieden een compleet
                      gelokaliseerd aanbod (land van herkomst)
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-4 pb-0 pt-0 p-lg-0 mb-4">
                  <div className="d-flex justify-content-center">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/4.png"
                      alt=""
                      className="img-fluid col-6 col-md-10"
                    />
                  </div>
                  <div className="text-center text-lg-start">
                    <h5 className="poppins-medium">Fabrieksbezoek regelen</h5>
                    <p className="m-0">
                      om u diepgaande inzichten te geven in de modernste
                      machines en het productieproces
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-4 pb-0 pt-0 p-lg-0 mb-4">
                  <div className="d-flex justify-content-center">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/5.png"
                      alt=""
                      className="img-fluid col-6 col-md-10"
                    />
                  </div>
                  <div className="text-center text-lg-start">
                    <h5 className="poppins-medium">Productafronding</h5>
                    <p className="m-0">Nabemonstering en batchtesten</p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-4 pb-0 pt-0 p-lg-0 mb-4">
                  <div className="d-flex justify-content-center">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/6.png"
                      alt=""
                      className="img-fluid col-6 col-md-10"
                    />
                  </div>
                  <div className="text-center text-lg-start">
                    <h5 className="poppins-medium">
                      Ondersteuning bij aftersales
                    </h5>
                    <p className="m-0">
                      Samen met en reserveonderdelenplanning - Europa en de VS
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-4 pb-0 pt-0 p-lg-0 mb-4">
                  <div className="d-flex justify-content-center">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/7.png"
                      alt=""
                      className="img-fluid col-6 col-md-10"
                    />
                  </div>
                  <div className="text-center text-lg-start">
                    <h5 className="poppins-medium">
                      Verleng logistieke ondersteuning
                    </h5>
                    <p className="m-0">
                      Om de beste verzendoplossing mogelijk te maken met ons
                      uitgebreide partnernetwerk
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-4 pb-0 pt-0 p-lg-0 mb-4">
                  <div className="d-flex justify-content-center">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/8.png"
                      alt=""
                      className="img-fluid col-6 col-md-10"
                    />
                  </div>
                  <div className="text-center text-lg-start">
                    <h5 className="poppins-medium">Stuur uw wensen</h5>
                    <p className="m-0">(fietsafbeeldingen en specificaties)</p>
                  </div>
                </div>
              </SplideSlide>
            </Splide>
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center mt-5">
          <Modal
            language={"dutch"}
            className="primary-button fw-bold px-4 py-2 d-lg-block d-none"
          />
          <ModalMobile
            language={"dutch"}
            className="primary-button fw-bold px-4 py-2 d-lg-none"
          />
        </div>
        {/* amigo */}
        <div className="news my-5 py-5">
          <h2 className="poppins-medium mb-5 container">
            Amigo - Eén app | Onbegrensde mogelijkheden
          </h2>
          <div
            className="d-flex container pt-3"
            style={{ overflowX: "auto", gap: "2em" }}
          >
            <div className="mb-4 amigo-spec">
              <div className="position-relative">
                <img
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/amigo/fitness.png"
                  className="w-100"
                ></img>
                <div
                  className="position-absolute"
                  style={{ top: "17%", right: "17%" }}
                >
                  <div className="d-flex align-items-end">
                    <p className="fw-bold">Fitnessmodus</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-4 amigo-spec">
              <div className="position-relative">
                <img
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/amigo/diagnostic.png"
                  className="w-100"
                ></img>
                <div
                  className="position-absolute"
                  style={{ top: "13%", right: "14%" }}
                >
                  <div className="d-flex align-items-end">
                    <p className="fw-bold">
                      Volledige<br></br>fietsdiagnostiek
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-4 amigo-spec">
              <div className="position-relative">
                <img
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/amigo/sos.png"
                  className="w-100"
                ></img>
                <div
                  className="position-absolute"
                  style={{ top: "13%", right: "21%" }}
                >
                  <div className="d-flex align-items-end">
                    <p className="fw-bold">
                      Nood-SOS<br></br>-waarschuwing
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center mt-4 mt-lg-5">
          <Modal
            language={"dutch"}
            className="primary-button fw-bold px-4 py-2 d-lg-block d-none"
          />
          <ModalMobile
            language={"dutch"}
            className="primary-button fw-bold px-4 py-2 d-lg-none"
          />
        </div>
        {/* testimonials */}
        <div className="mt-5 py-lg-5 container testimonials">
          <h2 className="poppins-medium mb-3 mb-lg-5 container">
            Getuigenissen
          </h2>
          <div className="mt-5 mt-lg-0">
            <Splide
              className="mb-lg-0 p-0 align-items-center"
              options={{
                arrows: true,
                start: 0,
                drag: false,
                type: "loop",
                pagination: true,
                autoplay: true,
                gap: "1.5em",
                padding: "0em",
                pauseOnHover: true,
                resetProgress: false,
                perPage: 1,
                perMove: 1,
                speed: 1000,
                interval: 4000,
                breakpoints: {
                  700: {
                    drag: true,
                    arrows: false,
                    autoplay: false,
                    perPage: 1,
                    perMove: 1,
                    gap: "1em",
                    padding: "0em",
                    pauseOnHover: false,
                    resetProgress: false,
                  },
                },
              }}
              muted={true}
            >
              <SplideSlide className="h-100">
                <div className="d-flex p-lg-4" style={{ gap: "2em" }}>
                  <div className="w-25 d-none d-lg-block">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/testimonial1.png"
                      className="rounded-0"
                    ></img>
                  </div>
                  <div className="position relative">
                    <div className="position-absolute inverted-comma d-lg-none">
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/inverted.png"
                        className="img-fluid"
                      ></img>
                    </div>
                    <div className="ps-5 ps-lg-0">
                      <h4 className="poppins-medium text-uppercase mb-4">
                        “Een van de beste e-bikefabrikanten op de markt! Ik heb
                        consequent e-bikes gekregen die naar mijn smaak zijn
                        aangepast en ze stellen nooit teleur, 11/10
                        aanbevolen!...........”
                      </h4>
                      <p className="poppins-medium mb-0">James Mill</p>
                    </div>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="d-flex p-lg-4" style={{ gap: "2em" }}>
                  <div className="w-25 d-none d-lg-block">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/testimonial2.png"
                      className="rounded-0"
                    ></img>
                  </div>
                  <div className="position relative">
                    <div className="position-absolute inverted-comma d-lg-none">
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/inverted.png"
                        className="img-fluid"
                      ></img>
                    </div>
                    <div className="ps-5 ps-lg-0">
                      <h4 className="poppins-medium text-uppercase mb-4">
                        “De overstap naar Dynem was voor ons een game-changer!
                        Het hele proces, van inkoop tot levering, verliep
                        naadloos. Bovendien waren de besparingen die we
                        bereikten door onze productie naar India te verplaatsen
                        aanzienlijk. Een echte aanrader!"
                      </h4>
                      <p className="poppins-medium mb-0">Jake Severisson</p>
                    </div>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="d-flex p-lg-4" style={{ gap: "2em" }}>
                  <div className="w-25 d-none d-lg-block">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/testimonial3.png"
                      className="rounded-0"
                    ></img>
                  </div>
                  <div className="position relative">
                    <div className="position-absolute inverted-comma d-lg-none">
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/inverted.png"
                        className="img-fluid"
                      ></img>
                    </div>
                    <div className="ps-5 ps-lg-0">
                      <h4 className="poppins-medium text-uppercase mb-4">
                        "De samenwerking met Dynem was ronduit uitstekend. De
                        overgang van China naar India verliep ongelooflijk
                        soepel en de kostenbesparingen die we realiseerden waren
                        aanzienlijk. Geweldige partner voor de productie van
                        e-bikes!"
                      </h4>
                      <p className="poppins-medium mb-0">Carolina Spike</p>
                    </div>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="d-flex p-lg-4" style={{ gap: "2em" }}>
                  <div className="w-25 d-none d-lg-block">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/testimonial4.png"
                      className="rounded-0"
                    ></img>
                  </div>
                  <div className="position relative">
                    <div className="position-absolute inverted-comma d-lg-none">
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/inverted.png"
                        className="img-fluid"
                      ></img>
                    </div>
                    <div className="ps-5 ps-lg-0">
                      <h4 className="poppins-medium text-uppercase mb-4">
                        "Dynem maakte onze overstap naar Indiase productie
                        moeiteloos. Hun aandacht voor detail en toewijding aan
                        kwaliteit waren indrukwekkend, en de kostenvoordelen
                        waren onmiddellijk zichtbaar. We zijn zeer tevreden met
                        de overstap!”
                      </h4>
                      <p className="poppins-medium mb-0">Kevin Spearson</p>
                    </div>
                  </div>
                </div>
              </SplideSlide>
            </Splide>
          </div>
        </div>
        {/* news */}
        <div className="news">
          <div className="container mt-5 py-4 py-lg-5">
            <h2 className="poppins-medium mb-3 mb-lg-5">In het nieuws</h2>
            <Splide
              className="mb-2 mb-lg-0 p-0 align-items-center"
              options={{
                arrows: false,
                drag: false,
                type: "slide",
                pagination: false,
                autoplay: false,
                perMove: 1,
                gap: "2em",
                padding: "0em",
                pauseOnHover: true,
                resetProgress: false,
                perPage: 4,
                speed: 1000,
                interval: 3000,
                breakpoints: {
                  700: {
                    drag: true,
                    perPage: 1.3,
                    arrows: false,
                    perMove: 1,
                    gap: "1em",
                    padding: "0em",
                    pauseOnHover: false,
                    resetProgress: false,
                    focus: 0,
                    start: 0,
                    speed: 1000,
                    interval: 3000,
                  },
                },
              }}
              muted={true}
            >
              <SplideSlide className="h-100">
                <div className="p-3" style={{ backgroundColor: "white" }}>
                  <div>
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/News+1.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/kxan.png"
                    className="img-fluid mt-2"
                  ></img>
                  <p className="my-3 news-heading poppins-medium">
                    DYNEM begint met de bouw van 's werelds grootste
                    geïntegreerde elektrische fiets-gigafabriek.....
                  </p>
                  <small>May 09, 2024</small>
                  <div
                    className="w-100 text-center py-2 mt-2"
                    style={{
                      backgroundColor: "black",
                      minHeight: "max-content",
                    }}
                  >
                    <a
                      className="text-light text-decoration-none"
                      href="https://www.kxan.com/business/press-releases/cision/20240509IO08933/dynem-begins-construction-of-worlds-biggest-integrated-electric-cycle-gigafactory-in-india/"
                    >
                      Lees meer
                    </a>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-3" style={{ backgroundColor: "white" }}>
                  <div>
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/News+2.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/ktla.png"
                    className="img-fluid mt-2"
                  ></img>
                  <p className="my-3 news-heading poppins-medium">
                    India's DYNEM gaat 's werelds grootste geïntegreerde
                    e-bike-gigafabriek oprichten
                  </p>
                  <small>May 09, 2024</small>
                  <div
                    className="w-100 text-center py-2 mt-2"
                    style={{
                      backgroundColor: "black",
                      minHeight: "max-content",
                    }}
                  >
                    <a
                      className="text-light text-decoration-none"
                      href="https://fox59.com/business/press-releases/cision/20240509IO08933/dynem-begins-construction-of-worlds-biggest-integrated-electric-cycle-gigafactory-in-india/"
                    >
                      Lees meer
                    </a>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-3" style={{ backgroundColor: "white" }}>
                  <div>
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/News+3.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/benzinga.png"
                    className="img-fluid mt-2"
                  ></img>
                  <p className="my-3 news-heading poppins-medium">
                    DYNEM is baanbrekend voor de grootste geïntegreerde
                    elektrische fietsgigant ter wereld...
                  </p>
                  <small>May 09, 2024</small>
                  <div
                    className="w-100 text-center py-2 mt-2"
                    style={{
                      backgroundColor: "black",
                      minHeight: "max-content",
                    }}
                  >
                    <a
                      className="text-light text-decoration-none"
                      href="https://www.benzinga.com/pressreleases/24/05/n38730936/dynem-begins-construction-of-worlds-biggest-integrated-electric-cycle-gigafactory-in-india"
                    >
                      Lees meer
                    </a>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-3" style={{ backgroundColor: "white" }}>
                  <div>
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/News+4.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/fox8.png"
                    className="img-fluid mt-2"
                  ></img>
                  <p className="my-3 news-heading poppins-medium">
                    DYNEM versterkt India's productiecapaciteiten: 's werelds
                    grootste...
                  </p>
                  <small>May 09, 2024</small>
                  <div
                    className="w-100 text-center py-2 mt-2"
                    style={{
                      backgroundColor: "black",
                      minHeight: "max-content",
                    }}
                  >
                    <a
                      className="text-light text-decoration-none"
                      href="https://www.benzinga.com/pressreleases/24/05/n38730936/dynem-begins-construction-of-worlds-biggest-integrated-electric-cycle-gigafactory-in-india"
                    >
                      Lees meer
                    </a>
                  </div>
                </div>
              </SplideSlide>
            </Splide>
          </div>
        </div>
        {/* FAQs */}
        <div className="container mt-5 pt-lg-5">
          <div className="d-flex faq-landing">
            <div className="faq-landing-1">
              <h2 className="poppins-medium mb-2 mb-lg-4">
                Veelgestelde vragen
              </h2>
              <p className="mb-lg-5">
                Heeft u vragen? We hebben al antwoorden. Het is alsof we je
                kunnen horen denken.
              </p>
              <div className="d-lg-block d-none">
                <h4 className="poppins-medium mb-2">Hebben wij iets gemist?</h4>
                <p className="mb-lg-5">Bel één op één met onze experts.</p>
                <div className="req-call my-5 my-lg-0">
                  <a
                    href="tel:+918956703515"
                    className="btn btn-dark px-5 d-flex justify-content-between rounded-0"
                    style={{ width: "fit-content" }}
                  >
                    Vraag een oproep aan
                  </a>
                </div>
              </div>
            </div>
            <div className="accordion faq-landing-2" id="accordionExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    Wat maakt DYNEM anders dan andere e-bikefabrikanten?
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body poppins-thin">
                    <p>
                      DYNEM valt op door zijn baanbrekende innovatie,
                      milieuvriendelijke werkwijzen en uitgebreide
                      aanpassingsmogelijkheden. Ons ultramoderne gigafactory-,
                      wereldwijde compliance- en deskundige team zorgt voor
                      topkwaliteit en naadloze ondersteuning van productie tot
                      after-sales, waardoor u alleen maar gemoedsrust heeft.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Kan ik mijn e-bike personaliseren met DYNEM?
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                      Absoluut! DYNEM biedt uitgebreide
                      aanpassingsmogelijkheden, waardoor u uw e-bike kunt
                      afstemmen op uw specifieke stijl en behoeften. Met onze
                      OEM-service kunt u de branding, specificaties en functies
                      aanpassen aan uw voorkeuren.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Hoeveel e-bikes produceert DYNEM jaarlijks?
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                      DYNEM heeft de capaciteit om jaarlijks 500.000 e-bikes te
                      produceren en aan te passen. Onze efficiënte
                      productieprocessen en infrastructuur van wereldklasse
                      stellen ons in staat om aan de groeiende mondiale vraag te
                      voldoen.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingFour">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    Wat zijn de voordelen van de antidumpingoplossingen van
                    DYNEM?
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                      Dankzij de juridische antidumpingoplossingen van DYNEM
                      kunnen importeurs tot 87% aan belastingen besparen. Onze
                      producten voldoen aan de mondiale regelgeving, waardoor
                      een gelijk speelveld zonder antidumpingheffingen wordt
                      gegarandeerd. Dit maakt DYNEM een kosteneffectieve en
                      conforme keuze voor importeurs over de hele wereld.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingfive">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    Hoe ondersteunt DYNEM duurzaamheid?
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFive"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                      DYNEM zet zich in voor duurzaamheid door middel van
                      milieuvriendelijke praktijken en innovatieve technologie.
                      Onze e-bikes zijn ontworpen om de ecologische voetafdruk
                      te verkleinen en een groenere toekomst te bevorderen, in
                      lijn met onze visie op duurzame mobiliteit.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingSix">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    Welke soort after-salesondersteuning biedt DYNEM?
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingSix"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                      DYNEM geeft prioriteit aan klanttevredenheid met
                      toegewijde after-sales ondersteuning. Vanaf de eerste
                      aankoop tot het onderhoud op lange termijn streeft ons
                      team ernaar naadloze en betrouwbare hulp te bieden, zodat
                      onze klanten een soepele en bevredigende ervaring kunnen
                      garanderen.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-lg-none text-center mt-3">
              <h4 className="poppins-medium mb-2">Hebben wij iets gemist?</h4>
              <p className="mb-lg-5">Bel één op één met onze experts.</p>
              <div className="req-call my-4">
                <a
                  href="tel:+918956703515"
                  className="btn btn-dark px-5 d-flex justify-content-between rounded-0"
                  style={{ width: "fit-content" }}
                >
                  Vraag een oproep aan
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="d-lg-block d-none">
          <div className="d-flex justify-content-center align-items-center mt-4 mt-lg-5">
            <Modal
              language={"dutch"}
              className="primary-button fw-bold px-4 py-2"
            />
          </div>
        </div>

        {/* contact us */}
        <div className="mt-5 py-lg-5" style={{ backgroundColor: "black" }}>
          <div className="container py-lg-5 py-4">
            <div className="d-flex mb-3 mb-lg-0" style={{ gap: "4em" }}>
              <div className="d-none d-lg-block w-50">
                <div className="row">
                  <div className="col-6 p-0">
                    <div className="d-flex justify-content-end">
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/grid1.png"
                        className="w-75 p-3"
                      ></img>
                    </div>
                    <div className="d-flex justify-content-end">
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/grid5.png"
                        className="w-100 p-3"
                      ></img>
                    </div>
                  </div>
                  <div className="col-6 p-0">
                    <div className="d-flex justify-content-start mt-5">
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/grid2.png"
                        className="w-100 p-3"
                      ></img>
                    </div>
                    <div className="d-flex justify-content-start">
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/grid4.png"
                        className="w-75 p-3"
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-none d-lg-block">
                <h2 className="poppins-medium mb-4 text-light">
                  Laten we verbinding maken
                </h2>
                <form className="contact-us-landing" onSubmit={submit}>
                  <div class="mb-3 d-flex" style={{ gap: "1em" }}>
                    <div className="w-50">
                      <label
                        for="exampleInputEmail1"
                        className="form-label text-light"
                      >
                        Volledige naam*
                      </label>
                      <input
                        type="text"
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                        required
                        class="form-control rounded-0"
                        aria-describedby="emailHelp"
                      />
                    </div>
                    <div className="w-50">
                      <label
                        for="exampleInputEmail1"
                        className="form-label text-light"
                      >
                        Land*
                      </label>
                      <input
                        type="text"
                        value={country}
                        onChange={(e) => {
                          setCountry(e.target.value);
                        }}
                        required
                        class="form-control rounded-0"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  </div>
                  <div class="mb-3 d-flex" style={{ gap: "1em" }}>
                    <div class="w-50">
                      <label
                        for="exampleInputPassword1"
                        class="form-label text-light"
                      >
                        Contactnr*
                      </label>
                      <PhoneInput
                        value={phoneNumber}
                        onChange={setPhoneNumber}
                        required
                        defaultCountry="IN"
                        className="form-control w-100 rounded-0"
                      />
                    </div>
                    <div className="w-50">
                      <label
                        for="exampleInputEmail1"
                        className="form-label text-light"
                      >
                        Werk e-mail*
                      </label>
                      <input
                        type="email"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        required
                        class="form-control rounded-0"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  </div>

                  <div class="mb-4 d-flex" style={{ gap: "1em" }}>
                    <div className="w-100">
                      <label
                        for="exampleInputEmail1"
                        className="form-label text-light"
                      >
                        Organisatie*
                      </label>
                      <input
                        type="text"
                        value={companyName}
                        required
                        onChange={(e) => {
                          setCompanyName(e.target.value);
                        }}
                        class="form-control rounded-0"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label
                      for="exampleInputEmail1"
                      className="form-label text-light"
                    >
                      Plaats uw aanvraag*
                    </label>
                    <textarea
                      class="form-control rounded-0"
                      required
                      id="exampleFormControlTextarea1"
                      rows="3"
                      value={enquiry}
                      onChange={(e) => {
                        setEnquiry(e.target.value);
                      }}
                    ></textarea>
                  </div>
                  <button
                    type="submit"
                    class="btn btn-light w-100 poppins-medium rounded-0"
                  >
                    Ontvang een offerte
                  </button>
                </form>
              </div>
            </div>
            <div className="d-lg-none mb-5">
              <h2 className="poppins-medium mb-4 text-light">
                Laten we verbinding maken
              </h2>
              <form className="contact-us-landing" onSubmit={submit}>
                <div class="mb-3 d-flex" style={{ gap: "1em" }}>
                  <div className="w-50">
                    <label
                      for="exampleInputEmail1"
                      className="form-label text-light"
                    >
                      Volledige naam*
                    </label>
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      required
                      class="form-control rounded-0"
                      aria-describedby="emailHelp"
                    />
                  </div>
                  <div className="w-50">
                    <label
                      for="exampleInputEmail1"
                      className="form-label text-light"
                    >
                      Land*
                    </label>
                    <input
                      type="text"
                      value={country}
                      onChange={(e) => {
                        setCountry(e.target.value);
                      }}
                      required
                      class="form-control rounded-0"
                      aria-describedby="emailHelp"
                    />
                  </div>
                </div>
                <div class="mb-3 d-flex" style={{ gap: "1em" }}>
                  <div class="w-50">
                    <label
                      for="exampleInputPassword1"
                      class="form-label text-light"
                    >
                      Contactnr*
                    </label>
                    <PhoneInput
                      value={phoneNumber}
                      onChange={setPhoneNumber}
                      required
                      defaultCountry="IN"
                      className="form-control w-100 rounded-0"
                    />
                  </div>
                  <div className="w-50">
                    <label
                      for="exampleInputEmail1"
                      className="form-label text-light"
                    >
                      Werk e-mail*
                    </label>
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      required
                      class="form-control rounded-0"
                      aria-describedby="emailHelp"
                    />
                  </div>
                </div>

                <div class="mb-4 d-flex" style={{ gap: "1em" }}>
                  <div className="w-100">
                    <label
                      for="exampleInputEmail1"
                      className="form-label text-light"
                    >
                      Organisatie*
                    </label>
                    <input
                      type="text"
                      value={companyName}
                      required
                      onChange={(e) => {
                        setCompanyName(e.target.value);
                      }}
                      class="form-control rounded-0"
                      aria-describedby="emailHelp"
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <label
                    for="exampleInputEmail1"
                    className="form-label text-light"
                  >
                    Plaats uw aanvraag*
                  </label>
                  <textarea
                    class="form-control rounded-0"
                    id="exampleFormControlTextarea1"
                    required
                    rows="3"
                    value={enquiry}
                    onChange={(e) => {
                      setEnquiry(e.target.value);
                    }}
                  ></textarea>
                </div>
                <button
                  type="submit"
                  class="btn btn-light w-100 poppins-medium rounded-0"
                >
                  Ontvang een offerte
                </button>
              </form>
            </div>
            <div className="row p-2 d-lg-none">
              <div className="col-6 p-0">
                <div className="d-flex justify-content-end">
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/grid1.png"
                    className="w-75 p-2"
                  ></img>
                </div>
                <div className="d-flex justify-content-end">
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/grid5.png"
                    className="w-100 p-2"
                  ></img>
                </div>
              </div>
              <div className="col-6 p-0">
                <div className="d-flex justify-content-start mt-5">
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/grid2.png"
                    className="w-100 p-2"
                  ></img>
                </div>
                <div className="d-flex justify-content-start">
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/grid4.png"
                    className="w-75 p-2"
                  ></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default GetQuoteDutch;
