import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../css/navbar.css";
import logo from "../images/brandLogos/Dynem red white.png";
import Button from "./Button";
import { Icon } from "@iconify/react";
import Modal from "./Modal";
import ModalMobile from "./ModalMobile";

const Navbar = () => {
  const [open, openNav] = useState(false);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  }, []);

  const closeNavforDeadLink = () => {
    openNav(false);
  };
  return (
    <>
      {/* desktop navbar */}
      <div
        className="navbar position-sticky navbar-big-screens"
        style={{ zIndex: "99", top: "0%" }}
      >
        <div className="container">
          <div className=" w-100 ">
            <div className="row w-100">
              <div className="col-2 d-flex justify-content-center">
                <Link to={"/"}>
                  {" "}
                  <img src={logo} alt="dynem-logo" className="logo"></img>
                </Link>
              </div>
              <div className="col-8 d-flex nav-items">
                <div className="dropdown">
                  <p
                    className="m-auto dropdown-heading nav-item fw-bold"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseExample"
                  >
                    Products&nbsp;
                    <Icon
                      icon="dashicons:arrow-down-alt2"
                      width="1.5em"
                      height="1.5em"
                      style={{ color: "#ff0000" }}
                    />
                  </p>
                  <div
                    className="
								dropdown-content dropdown-content-3"
                  >
                    <div className=" mt-3  dropdown-content-list">
                      <Link to="/e-bikes" className="text-decoration-none ">
                        <p className="m-0  pt-4 p-3 nav-border-bottom nav-link">
                          E-Bikes
                        </p>
                      </Link>
                      <Link to="/components" className="text-decoration-none">
                        <p className="m-0  pt-4 p-3 nav-border-bottom nav-link">
                          Components
                        </p>
                      </Link>
                      <Link to="/tech" className="text-decoration-none">
                        <p className="m-0  pt-4 p-3 nav-border-bottom nav-link">
                          Tech
                        </p>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="">
                  <Link
                    className="text-decoration-none nav-item fw-bold"
                    to={"/custom-bike"}
                  >
                    Build your E-Bike
                  </Link>
                </div>
                <div className="">
                  <Link
                    className="text-decoration-none nav-item fw-bold"
                    to={"/legal-anti-dumping-solution"}
                  >
                    Legal Anti Dumping Solution
                  </Link>
                </div>
                <div className="">
                  <Link
                    className="text-decoration-none nav-item fw-bold"
                    to={"/about-us"}
                  >
                    About Us
                  </Link>
                </div>
                <div className="">
                  <Link
                    className="text-decoration-none nav-item fw-bold"
                    to={"/contact-us"}
                  >
                    Contact US
                  </Link>
                </div>
              </div>
              <div className="col-2 d-flex justify-content-center align-items-center">
                <Modal className="primary-button fw-bold px-3 py-2" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* mobile navbar */}
      <div
        className="position-sticky navbar navbar-small-screens"
        style={{ zIndex: "99", top: "0%" }}
      >
        <div className="w-100 d-flex px justify-content-between align-items-center px-3 ">
          <Link to="/">
            <img src={logo} className="logo" alt="dyem-logo" />
          </Link>

          <div className="d-flex align-items-center" style={{ gap: "1.5em" }}>
            <Icon
              icon="bx:menu-alt-left"
              width="2em"
              height="2em"
              style={{ color: "#ff0000" }}
              onClick={() => openNav(true)}
            />
          </div>
        </div>
      </div>
      <div
        className={`mobile-sidenavbar navbar-small-screens ${
          open ? "navbar-open" : "navbar-off"
        } `}
      >
        <div className="p-2">
          <div className="d-flex p-1 pe-2 align-items-center justify-content-between">
            <Link to="/" onClick={closeNavforDeadLink}>
              <img src={logo} className="logo" alt="dynem-logo" />
            </Link>
            <div className="" onClick={() => openNav(false)}>
              <Icon
                icon="material-symbols:close"
                width="1.5em"
                height="1.5em"
                style={{ color: "#ff0000" }}
              />
            </div>
          </div>
          <div className="nav-links text-light p-3 position-relative">
            <hr className="mob-nav-hr mt-4 " />
            <Link
              to={"/"}
              className="mt-2  text-decoration-none "
              onClick={closeNavforDeadLink}
            >
              <div className="pe-2">
                <p className="mb-1 mt-1 text-light">Home</p>
              </div>
            </Link>
            <hr className=" mob-nav-hr mt-4 " />
            <div className="pe-2">
              <div
                className="d-flex justify-content-between align-items-center"
                data-bs-toggle="collapse"
                data-bs-target="#collapseExample1"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                <p className="mb-0 mt-1">Products</p>
                <Icon
                  icon="dashicons:arrow-down-alt2"
                  width="1.5em"
                  height="1.5em"
                  style={{ color: "#ff0000" }}
                />
              </div>

              <div
                className="collapse rounded-3 border-r-5 mt-4 p-3 "
                style={{
                  background: "#ffffff",
                }}
                id="collapseExample1"
              >
                <Link
                  to={"/e-bikes"}
                  onClick={closeNavforDeadLink}
                  className="mt-2 text-decoration-none dynem-color"
                >
                  E-bikes
                </Link>

                <hr className="mob-nav-hr" />
                <Link
                  to={"/components"}
                  onClick={closeNavforDeadLink}
                  className="mt-2 text-decoration-none dynem-color"
                >
                  Components
                </Link>

                <hr className="mob-nav-hr" />
                <Link
                  to={"/tech"}
                  onClick={closeNavforDeadLink}
                  className="mt-2 text-decoration-none dynem-color"
                >
                  Tech
                </Link>
              </div>
            </div>
            <hr className="mob-nav-hr mt-4 " />
            <div className="pe-2">
              <div className="d-flex justify-content-between align-items-center">
                <Link
                  to={"/custom-bike"}
                  className="mt-2  text-light   text-decoration-none"
                  onClick={closeNavforDeadLink}
                >
                  <p className="mb-0 mt-1">Build Your E-Bike</p>
                </Link>
              </div>
            </div>
            <hr className="mob-nav-hr mt-4 " />
            <div className="pe-2">
              <div className="d-flex justify-content-between align-items-center">
                <Link
                  to={"/legal-anti-dumping-solution"}
                  className="mt-2  text-light   text-decoration-none"
                  onClick={closeNavforDeadLink}
                >
                  <p className="mb-0 mt-1">Legal Anti Dumping Solution</p>
                </Link>
              </div>
            </div>
            <hr className="mob-nav-hr mt-4 " />
            <div className="pe-2">
              <div className="d-flex justify-content-between align-items-center">
                <Link
                  to={"/about-us"}
                  className="mt-2  text-light   text-decoration-none"
                  onClick={closeNavforDeadLink}
                >
                  <p className="mb-0 mt-1">About Us</p>
                </Link>
              </div>
            </div>
            <hr className="mt-4 mob-nav-hr" />
            <div className="pe-2">
              <div className="d-flex justify-content-between align-items-center">
                <Link
                  to={"/contact-us"}
                  className="mt-2  text-light   text-decoration-none"
                  onClick={closeNavforDeadLink}
                >
                  <p className="mb-0 mt-1">Contact Us</p>
                </Link>
              </div>
            </div>
            <hr className="mt-4 mb-5 mob-nav-hr" />
            <div className="d-flex align-items-center justify-content-center">
              <ModalMobile className="d-lg-none primary-button fw-bold px-4 py-2 rounded-3" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
